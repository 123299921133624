import Vue from 'vue/dist/vue.js';
import store from './store';

import { dayjsFormat, dayjsToISO, userDate, userTime, userDateTime, userCurrency, price } from './filters/index';
import GnModuleEntity from './views/GnModuleEntity';
import currency from 'vue2-filters/src/other/currency';
import date from 'vue-filter/src/other/date';

import Transport from './transport';

let initialized = false;

const init = () => {
  const urlParams = new URLSearchParams(window.location.search);

  if (!urlParams.get('module_name') || !urlParams.get('module_view')) return;

  if (urlParams.get('module_view') == 'details' && !urlParams.get('record_id')) return;

  if (initialized) return;
  initialized = true;

  Vue.prototype.$socketBus = new Vue();
  Vue.prototype.$commonBus = new Vue();

  store.registerModule('gnStore', { namespaced: true, ...gnStore })

  Vue.config.productionTip = false;

  Vue.use(GnDirectives.Loading);
  
  Vue.use(Transport);
  Vue.use(Generator);
  Vue.component('gn-view', GnView);
  Vue.component('gn-module-entity', GnModuleEntity);
  
  Object.keys(GnComponents).forEach((key) => {
    Vue.component(`${key}`, GnComponents[key]);
  });
  
  Vue.filter('currency', currency);
  Vue.filter('formatDate', date);
  Vue.filter('translateReplace', GnDefaultFilters.translateReplace);
  Vue.filter('dayjsFormat', dayjsFormat);
  Vue.filter('dayjsToISO', dayjsToISO);
  Vue.filter('userDate', userDate);
  Vue.filter('userTime', userTime);
  Vue.filter('userDateTime', userDateTime);
  Vue.filter('userCurrency', userCurrency);
  Vue.filter('price', price);
  
  Vue.filter('translate', (initValue) => {
    const regExp = new RegExp(/^\w+\|t$/);
    const value = typeof initValue === 'string'
      ? initValue.trim()
      : initValue;
  
    if (value && regExp.test(value)) {
      const key = value.split('|')[0];
      return store.state.gnStore.translations[key] || key;
    }
    return value;
  });
  
  Vue.prototype.$options = { 
    filters: { 
      dayjsFormat, 
      dayjsToISO, 
      userDate, 
      userTime, 
      userDateTime, 
      userCurrency, 
      price 
    }
  };

  Vue.prototype.$route = {
    params: { 
      id: urlParams.get('record_id') 
    },
    meta: {
      moduleName: urlParams.get('module_name'),
      view: urlParams.get('module_view')
    }
  }; // new Vue();

  Vue.prototype.$notify = GnAdditionals.Notification;
  
  const vm = new Vue({
    el: '#generator-widget',
    store,
    template: `
      <div>
        <gn-module-entity />
      </div>
    `
  });
};

export default () => {
  apos.util.widgetPlayers.generator = {
    selector: '[data-generator-widget]',
    player: function (el) {     
      if (apos.adminBar) {
        const container = document.querySelector('#generator-widget');
        document.querySelectorAll('section[data-generator-widget] .loading')[0].style.display = 'none'
        container.innerHTML = `
          <div style="border: 1px solid #c5c9d1; border-radius: 5px; padding: 5rem; margin-bottom: 3rem; text-align: center;">
            <h2 style="font-weight: bold;">Generator Widget</h2>
            <p style="color: #a3a6ad;">Dynamic content will be displayed here. Disable admin mode to activate this widget.</p>
          </div>
        `;
        return;
      }
       
      init();
    }
  };
};