import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);

if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status == 401) {
      localStorage.removeItem('erpPortalApiAuthToken');
      localStorage.setItem('erpPortalApiAuthReturnUrl', window.location.href);
      setTimeout(() => window.location.href = '/signin', 3000);
      error.response.data.error = 'Unauthorized. Redirecting...';
    }

    return Promise.reject(error);
  }
);

let initialized = false;

const init = async () => {
  if (initialized) return;
  initialized = true;

  const urlParams = new URLSearchParams(window.location.search);

  const loading = document.querySelector('section[data-payment-widget] .loading');

  const form = document.getElementById('payment-widget-form');

  let stripe_public_key = null;

  try {
    loading.style.display = 'block';
    
    const response = await _axios({
      method: 'GET',
      url: '/v1/sales_order/details',
      params: {
        so_id: urlParams.get('so_id')
      }
    });
  
    if (!(response.data && response.data.data && response.data.data.body && response.data.data.body.so_id && response.data.data.partsList.length > 0)) {
      throw new Error('Purchase Order not found');
    }

    document.querySelector('section[data-payment-widget] .modal-header .total-value').innerHTML = `${response.data.data.currency.currency_symbol}${response.data.data.body.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    document.querySelector('section[data-payment-widget] h5').innerHTML = `Customer Order Details: <a href="/customer-order?module_name=PO&module_view=details&record_id=${response.data.data.body.so_id}" target="_blank">${response.data.data.body.so_no}</a>`;
    document.querySelector('section[data-payment-widget] button[type="cancel"]').onclick = () => location.href = `/customer-order?module_name=PO&module_view=details&record_id=${response.data.data.body.so_id}`;

    const stripe_config_response = await _axios({
      method: 'GET',
      url: '/v1/payments/stripe/get-config'
    });

    stripe_public_key = stripe_config_response.data && stripe_config_response.data.data && stripe_config_response.data.data.public_key;

    loading.style.display = 'none';
  } catch (error) {
    const title = (error && error.response && error.response.data && error.response.data.error) || 'Purchase Order not found. Contact with administrator';

    GnAdditionals.Notification({
      title,
      type: 'error'
    });

    form.innerHTML = `
      <div style="text-align: center;">
        <p style="color: #a3a6ad;">${title}</p>
      </div>
    `;

    loading.style.display = 'none';

    return;
  }

  if (!stripe_public_key) return;

  const stripe = Stripe(stripe_public_key);
  const elements = stripe.elements();

  var style = {
    base: {
      color: '#000'
    }
  };

  const card = elements.create('card', { style });
  card.mount('#payment-card-element');
  
  form.addEventListener('submit', e => {
    e.preventDefault();

    loading.style.display = 'block';
    
    stripe.createToken(card)
      .then(res => {
        if (res.error) {
          throw { response: { data: { error: res.error.message } } };
        }

        return _axios({
          method: 'POST',
          url: '/v1/payments/stripe/create',
          params: {
            so_id: urlParams.get('so_id'),
            stripeToken: res.token.id
          }
        });
      })
      .then(() => {
        GnAdditionals.Notification({
          title: 'Your payment has been received. Our manager will contact you shortly. Redirecting...',
          type: 'success'
        });

        setTimeout(() => window.location.href = `/customer-order?module_name=PO&module_view=details&record_id=${urlParams.get('so_id')}`, 3000);
      })
      .catch(error => {
        GnAdditionals.Notification({
          title: error.response && error.response.data && error.response.data.error || 'Something went wrong please try again',
          type: 'error'
        });
      })
      .finally(() => {
        loading.style.display = 'none';
      });
  });
};

export default () => {
  apos.util.widgetPlayers.payment = {
    selector: '[data-payment-widget]',
    player: function (el) {
      if (apos.adminBar) {
        const form = document.getElementById('payment-widget-form');
        form.innerHTML = `
          <div style="text-align: center;">
            <h2 style="font-weight: bold; text-align: center;">Order Payment Widget</h2>
            <p style="color: #a3a6ad;">Dynamic content will be displayed here. Disable admin mode to activate this widget.</p>
          </div>
        `;
        document.querySelector('section[data-payment-widget] button[type="submit"]').onclick = e => {
          e.preventDefault();
          GnAdditionals.Notification({
            title: 'Disable admin mode to activate this widget',
            type: 'error'
          });
        };
        return;
      }

      init();
    }
  };
};