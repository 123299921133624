import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);

if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

let initialized = false;

let product = null;
let quote_id = null;

const addToCart = async function () {
  this.disabled = true;

  try {
    if (localStorage.getItem('erpPortalApiAuthToken')) {
      await _axios({
        method: 'POST',
        url: '/v1/quote/line/add',
        data: {
          parent_module: product.parent_module,
          parent_entity_id: product.parent_entity_id,
          parent_line_id: product.parent_line_id,
          quantity: product.moq
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`
        }
      });
      // const shoppingCartCount = localStorage.getItem('erpPortalShoppingCartCount');
      // localStorage.setItem('erpPortalShoppingCartCount', +shoppingCartCount + 1);
      window.countShoppingCart();
    } else {
      let shoppingCart = localStorage.getItem('erpPortalShoppingCart');

      if (shoppingCart) {
        shoppingCart = JSON.parse(shoppingCart);
      } else {
        shoppingCart = [];
      }

      shoppingCart.push({
        parent_module: product.parent_module,
        parent_entity_id: product.parent_entity_id,
        parent_line_id: product.parent_line_id,
        quantity
      });

      localStorage.setItem('erpPortalShoppingCart', JSON.stringify(shoppingCart));
    }

    GnAdditionals.Notification({
      title: 'This Product was added to the Shopping Cart',
      type: 'success'
    });
  } catch (e) {
    const message = e && e.response && e.response.data && e.response.data.error;
    GnAdditionals.Notification({
      title: message || 'Something went wrong please try again',
      type: 'error'
    });
  }

  this.disabled = false;
};

const createRFQ = async function () {
  this.disabled = true;

  try {
    await _axios({
      method: 'POST',
      url: '/v1/rfq/add',
      data: {
        parent_module: product.parent_module,
        parent_entity_id: product.parent_entity_id,
        parent_line_id: product.parent_line_id,
        quantity: product.moq
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`
      }
    });

    GnAdditionals.Notification({
      title: 'Request for Quotation has been successfully created',
      type: 'success'
    });
  } catch (e) {
    const message = e && e.response && e.response.data && e.response.data.error;
    GnAdditionals.Notification({
      title: message || 'Something went wrong please try again',
      type: 'error'
    });
  }

  this.disabled = false;
};

const copyQuoteLinesToCart = async function () {
  this.disabled = true;

  try {
    await _axios({
      method: 'POST',
      url: '/v1/quote/line/copy',
      params: {
        quote_id
      }
    });

    GnAdditionals.Notification({
      title: 'This Product was added to the Shopping Cart',
      type: 'success'
    });
  } catch (e) {
    const message = e && e.response && e.response.data && e.response.data.error;
    GnAdditionals.Notification({
      title: message || 'Something went wrong please try again',
      type: 'error'
    });
  }
  window.countShoppingCart();

  this.disabled = false;
};

const init = async () => {
  if (initialized) return;
  initialized = true;

  const urlParams = new URLSearchParams(window.location.search);

  const button = document.querySelectorAll('section[data-add-to-cart-widget] #add-to-cart-btn')[0];

  try {
    button.disabled = true;

    switch (location.pathname) {
      case '/product-details': {
        const response = await _axios({
          method: 'GET',
          url: '/v1/catalog/get-product',
          params: {
            cid: window.erpPortalApiCompany,
            parent_module: urlParams.get('parent_module'),
            parent_entity_id: urlParams.get('parent_entity_id'),
            parent_line_id: urlParams.get('parent_line_id') || 0,
          }
        });

        if (!(response.data && response.data.data && response.data.data.body && response.data.data.body.id)) throw 'Product not found';

        product = response.data.data.body;

        if (product.unit_price && product.unit_price.length > 0) {
          button.onclick = addToCart;
        } else if (localStorage.getItem('erpPortalApiAuthToken')) {
          button.onclick = createRFQ;
          button.innerHTML = 'Create Request for Quotation';
        }
        break;
      }

      case '/quote': {
        quote_id = urlParams.get('record_id');
        button.onclick = copyQuoteLinesToCart;
        break;
      }
    }

    button.disabled = false;
  } catch (e) {
    const message = e && e.response && e.response.data && e.response.data.error;
    GnAdditionals.Notification({
      title: message || 'Something went wrong please try again',
      type: 'error'
    });
  }
};

export default () => {
  apos.util.widgetPlayers['add-to-cart'] = {
    selector: '[data-add-to-cart-widget]',
    player: function (el) {
      if (apos.adminBar) {
        const button = document.getElementById('add-to-cart-btn');
        button.onclick = e => {
          GnAdditionals.Notification({
            title: 'Disable admin mode to activate this widget',
            type: 'error'
          });
        };
        return;
      }

      init();
    }
  };
};
