import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);

if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status == 401) {
      localStorage.removeItem('erpPortalApiAuthToken');
      localStorage.setItem('erpPortalApiAuthReturnUrl', window.location.href);
      setTimeout(() => window.location.href = '/signin', 3000);
      error.response.data.error = 'Unauthorized. Redirecting...';
    }

    return Promise.reject(error);
  }
);

function toQueryString(obj, urlEncode) {
  //
  // Helper function that flattens an object, retaining key structer as a path array:
  //
  // Input: { prop1: 'x', prop2: { y: 1, z: 2 } }
  // Example output: [
  //     { path: [ 'prop1' ],      val: 'x' },
  //     { path: [ 'prop2', 'y' ], val: '1' },
  //     { path: [ 'prop2', 'z' ], val: '2' }
  // ]
  //
  function flattenObj(x, path) {
    var result = [];

    path = path || [];
    Object.keys(x).forEach(function(key) {
      if (!x.hasOwnProperty(key)) return;

      var newPath = path.slice();
      newPath.push(key);

      var vals = [];
      if (typeof x[key] == 'object') {
        vals = flattenObj(x[key], newPath);
      } else {
        vals.push({
          path: newPath,
          val: x[key]
        });
      }
      vals.forEach(function(obj) {
        return result.push(obj);
      });
    });

    return result;
  } // flattenObj

  // start with  flattening `obj`
  var parts = flattenObj(obj); // [ { path: [ ...parts ], val: ... }, ... ]

  // convert to array notation:
  parts = parts.map(function(varInfo) {
    if (varInfo.path.length == 1) varInfo.path = varInfo.path[0];
    else {
      var first = varInfo.path[0];
      var rest = varInfo.path.slice(1);
      varInfo.path = first + '[' + rest.join('][') + ']';
    }
    return varInfo;
  }); // parts.map

  // join the parts to a query-string url-component
  var queryString = parts.map(function(varInfo) {
    return varInfo.path + '=' + varInfo.val;
  }).join('&');
  if (urlEncode) return encodeURIComponent(queryString);
  else return queryString;
}

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$transport', {
    get() {
      const self = this;

      return {
        send(path, data) {
          let method, params, body, emit, customAction, onReady;

          switch (path) {
            case '/v2/i18n/list':
              path = '/v1/i18n/list';
              method = 'GET';
              params = data.params;
              break;

            case '/v1/quote/extension-quote-bids/list':
              method = 'GET';
              params = { quote_id: '335', extension: data.params.extension };
              break;

            case '/v1/generator/workflow':
              method = 'POST';
              body = data;
              break;

            case '/v1/generator/config':
              method = 'GET';
              params = data.params;
              onReady = () => setTimeout(() => document.querySelectorAll('section[data-generator-widget] .loading')[0].style.display = 'none', 1500);
              break;

            case '/v1/module/field/edit':
              method = 'POST';
              body = data;
              emit = 'gnGlobalDataReload';

              if (!localStorage.getItem('erpPortalApiAuthToken') && data.params.module == 'SHOPPING_CART' && data.params.field == 'partsList.quantity') {
                method = null;
                body = null;

                customAction = () => {
                  const shoppingCart = JSON.parse(localStorage.getItem('erpPortalShoppingCart'));

                  const item = shoppingCart.find((item, i) => {
                    return i == data.params.line_id -1;
                  });

                  if (item) item.quantity = data.params.new_value;

                  localStorage.setItem('erpPortalShoppingCart', JSON.stringify(shoppingCart));

                  return Promise.resolve({ res: 1, data: {} });
                };
              }
              break;

            case '/v1/rfq/list':
              data.params.page = 1;
              data.params.page_size = 100;
              method = 'GET';
              path += `?${toQueryString(data.params)}`;
              break;

            case '/v1/quote/list':
              data.params.page = 1;
              data.params.page_size = 100;
              method = 'GET';
              path += `?${toQueryString(data.params)}`;
              break;

            case '/v1/sales_order/list':
              data.params.page = 1;
              data.params.page_size = 100;
              method = 'GET';
              path += `?${toQueryString(data.params)}`;
              break;

            case '/v1/invoice/list':
              data.params.page = 1;
              data.params.page_size = 100;
              method = 'GET';
              path += `?${toQueryString(data.params)}`;
              break;

            case '/v1/quote/details':
              method = 'GET';
              params = data.params;

              if (!localStorage.getItem('erpPortalApiAuthToken')) {
                method = 'POST';
                path = '/v1/catalog/get-shopping-cart';
                body = {
                  cid: window.erpPortalApiCompany,
                  shopping_cart: []
                };

                if (localStorage.getItem('erpPortalShoppingCart')) {
                  body.shopping_cart = JSON.parse(localStorage.getItem('erpPortalShoppingCart'));
                }
              }
              break;

            case '/v1/quote/line/delete':
              method = 'POST';
              body = data;
              emit = 'gnGlobalDataReload';

              if (!localStorage.getItem('erpPortalApiAuthToken')) {
                method = null;
                body = null;

                customAction = () => {
                  let shoppingCart = JSON.parse(localStorage.getItem('erpPortalShoppingCart'));

                  shoppingCart = shoppingCart.filter((item, i) => {
                    return i != data.params.line_id -1;
                  });

                  localStorage.setItem('erpPortalShoppingCart', JSON.stringify(shoppingCart));

                  return Promise.resolve({ res: 1, data: {} });
                };
              }
              break;
            case '/v1/quote/line/add':
              method = 'POST';
              body = {
                parent_module: data.params.parent_module,
                parent_entity_id: data.params.parent_entity_id,
                parent_line_id: data.params.parent_line_id,
                quantity: data.params.quantity
              };
              emit = 'gnGlobalDataReload';

              if (!localStorage.getItem('erpPortalApiAuthToken')) {
                method = null;
                body = null;

                customAction = () => {
                  let shoppingCart = localStorage.getItem('erpPortalShoppingCart');

                  if (shoppingCart) {
                    shoppingCart = JSON.parse(shoppingCart);
                  } else {
                    shoppingCart = [];
                  }

                  shoppingCart.push({
                    parent_module: data.params.parent_module,
                    parent_entity_id: data.params.parent_entity_id,
                    parent_line_id: data.params.parent_line_id,
                    quantity: data.params.quantity
                  });

                  localStorage.setItem('erpPortalShoppingCart', JSON.stringify(shoppingCart));

                  return Promise.resolve({ res: 1, data: {} });
                };
              }
              break;
            case '/v1/rfq/add':
              method = 'POST';
              body = {
                parent_module: data.params.parent_module,
                parent_entity_id: data.params.parent_entity_id,
                parent_line_id: data.params.parent_line_id,
                quantity: data.params.quantity
              };
              break;
            case '/v1/catalog/find-products':
              const urlParams = new URLSearchParams(window.location.search);
              method = 'GET';
              const filterParams = {
                cid: window.erpPortalApiCompany,
                page: urlParams.get('page')
              };
              if (urlParams.get('pn')) {
                filterParams.pn = urlParams.get('pn').split(',');
              }
              if (urlParams.get('cd')) {
                filterParams.cd = urlParams.get('cd').split(',');
              }
              if (urlParams.get('stock')) {
                filterParams.stock = urlParams.get('stock');
              }
              if (urlParams.get('category')) {
                filterParams.category = urlParams.get('category');
              }
              if (urlParams.get('group')) {
                filterParams.group = urlParams.get('group');
              }
              if (urlParams.get('subgroup')) {
                filterParams.sub_group = urlParams.get('subgroup');
              }
              path += `?${toQueryString(filterParams)}`;
              break;

            default:
              method = 'GET';
              params = data.params;
              break;
          }

          return (customAction ? customAction() : _axios({ method, url: path, params, data: body }))
            .then(response => {
              if (emit) self.$commonBus.$emit(emit);
              if (onReady) onReady();

              if (path.split('?')[0] === '/v1/catalog/find-products' && response.data.data.partsList) {
                for (let i = 0; i < response.data.data.partsList.length; i++) {
                  if (!localStorage.getItem('erpPortalApiAuthToken')) {
                    response.data.data.partsList[i].auth = false;
                  } else {
                    response.data.data.partsList[i].auth = true;
                  }

                  // add no image if gallery is empty
                  if (!(response.data.data.partsList[i].gallery && response.data.data.partsList[i].gallery.main && response.data.data.partsList[i].gallery.main.src)) {
                    response.data.data.partsList[i].gallery = { main: { src: 'images/no_image.png' }, additional: [] };
                  }
                }

                // catalog btns
                const prevBtn = document.getElementById('catalog-pagination-prev');
                const nextBtn = document.getElementById('catalog-pagination-next');
                const urlParams = new URLSearchParams(window.location.search);
                if (response.data.data.total) {
                  if (+urlParams.get('page') === 1) {
                    prevBtn.disabled = true;
                    prevBtn.style.opacity = 0.5;
                    prevBtn.style.cursor = 'not-allowed';
                  } else {
                    prevBtn.disabled = false;
                    prevBtn.style.opacity = 1;
                    prevBtn.style.cursor = 'pointer';
                  }
                  if (+urlParams.get('page') * 10 > response.data.data.total) {
                    nextBtn.disabled = true;
                    nextBtn.style.opacity = 0.5;
                    nextBtn.style.cursor = 'not-allowed';
                  } else {
                    nextBtn.disabled = false;
                    nextBtn.style.opacity = 1;
                    nextBtn.style.cursor = 'pointer';
                  }
                }
              }

              if (path.split('?')[0] === '/v1/quote/details' && response.data.data.partsList) {
                for (let i = 0; i < response.data.data.partsList.length; i++) {
                  // add no image if gallery is empty
                  if (!response.data.data.partsList[i].gallery || (!response.data.data.partsList[i].gallery.main && !response.data.data.partsList[i].gallery.additional.length)) {
                    response.data.data.partsList[i].gallery = { main: { src: 'images/no_image.png' }, additional: [] };
                  }
                }
              }
              if (path.split('?')[0] === '/v1/quote/line/add' ||
                path.split('?')[0] === '/v1/quote/line/delete' ||
                path.split('?')[0] === '/v1/quote/details' ||
                path.split('?')[0] === '/v1/quote/line/copy') {
                window.countShoppingCart();
              }

              return { response: response.data };
            })
            .catch(error => {
              self.$notify({
                title: (error.response && error.response.data && error.response.data.error) || 'Something went wrong please try again',
                type: 'error'
              });

              return Promise.reject();
            });
        },
      };
    },
  });
}
