import Vue from 'vue/dist/vue.js';
import Vuex from 'vuex';

import translate from '../translate';

Vue.use(Vuex);

const state = {
  translate,
  auth: { configCompany: {} }
};


const store = new Vuex.Store({
  state
});

export default store;