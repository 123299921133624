import currency from 'vue2-filters/src/other/currency';

export default function (value, symbol) {
  const defaultSymbol = '$';
  const targetSymbol = symbol ? `${symbol.toString()} ` : defaultSymbol;
  const f = x => ((x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0));
  let qty = f(+value);
  if (qty === 0 || qty === 1) {
    qty = 2;
  }
  if (qty > 4) {
    qty = 4;
  }
  return currency(value, targetSymbol, qty);
}
