export default {
    "MENU_CALENDAR":"CALENDAR",
    "MENU_ACCOUNTS":"ACCOUNTS",
    "MENU_CONTACTS":"CONTACTS",
    "MENU_RFQ":"RFQ",
    "MENU_QUOTES":"QUOTES",
    "MENU_SALES_ORDER":"SALES ORDER",
    "MENU_PURCHASE_ORDER":"PURCHASE ORDER",
    "MENU_PARTS":"PARTS",
    "MENU_COMPANY":"COMPANY",
    "MENU_DOCUMENTS":"DOCUMENTS",
    "MENU_TICKETS":"TICKETS",
    "MENU_MARKETING_AND_SALES":"MARKETING AND SALES",
    "MENU_KANBAN":"KANBAN",
    "MENU_QAR":"QAR",
    "MENU_EXCHANGE_AGREEMENT":"EXCHANGE AGREEMENT",
    "MENU_CONTRACTS":"CONTRACTS",
    "MENU_CAMPAIGNS":"CAMPAIGNS",
    "MENU_LEADS":"LEADS",
    "MENU_OPPORTUNITIES":"OPPORTUNITIES",
    "MENU_INVOICE":"INVOICE",
    "MENU_SUPPORT":"SUPPORT",
    "MENU_FAQ":"FAQ",
    "MENU_PROJECTS":"PROJECTS",
    "MENU_INVENTORY":"INVENTORY",
    "MENU_RECEIPT_CARD":"RECEIPT CARD",
    "MENU_ISSUE_CARD":"ISSUE CARD",
    "MENU_WARE_TRANSFER":"WARE. TRANSFER",
    "MENU_INCOMING_PRODUCTS":"INCOMING PRODUCTS",
    "MENU_OUTGOING_PRODUCTS":"OUTGOING PRODUCTS",
    "MENU_CATALOGS":"CATALOGS",
    "MENU_WAREHOUSES":"WAREHOUSES",
    "MENU_DELIVERY_NOTES":"DELIVERY NOTES",
    "MENU_BAI_HISTORY":"BAI HISTORY",
    "MENU_ASSETS":"ASSETS",
    "MENU_VENDORS":"VENDORS",
    "MENU_TOOLS":"TOOLS",
    "MENU_PDF_MAKER":"PDF MAKER",
    "MENU_BAI_WAREHOUSE":"BAI WAREHOUSE",
    "MENU_EMAIL_TEMPLATES":"EMAIL TEMPLATES",
    "MENU_ANALYTICS":"ANALYTICS",
    "MENU_REPORTS":"REPORTS",
    "MENU_SHIPPING":"SHIPPING",
    "MENU_MATERIAL_ARRIVAL":"MATERIAL ARRIVAL",
    "MENU_MATERIAL_ARRIVAL_HISTORY":"MATERIAL ARRIVAL HISTORY",
    "MENU_NOTICE":"NOTICE",
    "MENU_NOTICE_HISTORY":"NOTICE HISTORY",
    "MENU_INSPECTION_RECEIVING":"INSPECTION & RECEIVING",
    "MENU_INSPECTION_HISTORY":"INSPECTION HISTORY",
    "MENU_PACKING":"PACKING",
    "MENU_PACKING_HISTORY":"PACKING HISTORY",
    "MENU_CARGO_SIGN_OFF_FORM":"CARGO SIGN-OFF FORM",
    "MENU_CARGO_SIGN_OFF_FORM_HISTORY":"CARGO SIGN-OFF FORM HISTORY",
    "MENU_ORGANIZATION":"ORGANIZATION",
    "MENU_TRAINING":"TRAINING",
    "MENU_EMPLOYEES":"EMPLOYEES",
    "MENU_COURSES":"COURSES",
    "MENU_CLASS":"CLASS",
    "MENU_EQUIPMENT":"EQUIPMENT",
    "MENU_SETTINGS":"SETTINGS",
    "MENU_CORP_BANK_ACCOUNT":"CORP. BANK ACCOUNT",
    "WIDGET_PDF_MAKER_EDITOR_HEADER_SECTION":"Header",
    "WIDGET_PDF_MAKER_EDITOR_CONTENT_SECTION":"Content",
    "WIDGET_PDF_MAKER_EDITOR_FOOTER_SECTION":"Footer",
    "WIDGET_PDF_MAKER_EDITOR_BTN_LABEL_EXPORT_TO_PDF":"Export to PDF",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_LABEL_TO":"To",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_LABEL_SUBJECT":"Subject",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_LABEL_CC":"CC",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_LABEL_BCC":"BCC",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_PLACEHOLDER_TO_ADDRESS":"Select an option",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_BTN_LABEL_ADD_CC":"Add СС",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_BTN_LABEL_ADD_BCC":"Add BСС",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_BTN_LABEL_FILE_UPLOAD":"Drag/Drop files here or click the area. Max size: 25 MB",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_BTN_LABEL_EXPORT_PDF":"Click to add exported PDF",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_BTN_LABEL_SEND_EMAIL":"Send Email",
    "WIDGET_PDF_MAKER_COMPOSE_EMAIL_MESSAGE_SEND_EMAIL":"Email was sent",
    "WIDGET_PDF_MAKER_LINES_LABEL_DESCRIPTION":"Part Number/Description",
    "WIDGET_PDF_MAKER_LINES_LABEL_LINE_NO":"Line No",
    "WIDGET_PDF_MAKER_LINES_LABEL_PRODUCT_NAME":"Product Name",
    "WIDGET_PDF_MAKER_LINES_LABEL_COMMENT":"Comment",
    "WIDGET_PDF_MAKER_LINES_LABEL_CERTS":"Cert Type",
    "WIDGET_PDF_MAKER_LINES_LABEL_TRACE":"Trace",
    "WIDGET_PDF_MAKER_LINES_LABEL_SHOP":"Shop",
    "WIDGET_PDF_MAKER_LINES_LABEL_SN":"S/N",
    "WIDGET_PDF_MAKER_LINES_LABEL_WARRANTY":"Warranty",
    "WIDGET_PDF_MAKER_LINES_LABEL_NOTES":"Notes",
    "WIDGET_PDF_MAKER_LINES_LABEL_TYPE":"Type",
    "WIDGET_PDF_MAKER_LINES_LABEL_CD":"CD",
    "WIDGET_PDF_MAKER_LINES_LABEL_QTY":"QTY",
    "WIDGET_PDF_MAKER_LINES_LABEL_UOM":"UOM",
    "WIDGET_PDF_MAKER_LINES_LABEL_DUE_DATE":"Due Date",
    "WIDGET_PDF_MAKER_LINES_LABEL_UNIT_PRICE":"Unit Price",
    "WIDGET_PDF_MAKER_LINES_LABEL_LINE_TOTAL":"Line Total",
    "WIDGET_PDF_MAKER_SALESTYPE_OUTRIGHT":"Stock Outright",
    "WIDGET_PDF_MAKER_SALESTYPE_EXCHANGE":"Exchange",
    "WIDGET_PDF_MAKER_SALESTYPE_REPAIR":"Repair",
    "WIDGET_PDF_MAKER_MAIN_TEMPLATES":"Templates",
    "WIDGET_PDF_MAKER_MAIN_BTN_LABEL_EXPORT":"Export",
    "WIDGET_PDF_MAKER_MAIN_BTN_LABEL_EXPORT_WITH_CONFIG":"Export with config",
    "WIDGET_PDF_MAKER_MAIN_BTN_LABEL_GET_PART_CERTIFICATION":"Get Part Certification",
    "WIDGET_PDF_MAKER_MAIN_BTN_LABEL_PRINT":"Print",
    "WIDGET_PDF_MAKER_MAIN_BTN_LABEL_SEND_EMAIL":"Send Email",
    "WIDGET_PDF_MAKER_MAIN_BTN_LABEL_EDIT_AND_EXPORT":"Edit and Export",
    "WIDGET_PDF_MAKER_MAIN_LABEL_DECIMAL_POINT_SEPARATOR":"Decimal point separator",
    "WIDGET_PDF_MAKER_MAIN_LABEL_THOUSANDS_SEPARATOR":"Thousands separator",
    "WIDGET_PDF_MAKER_MAIN_LABEL_DATE_FORMAT":"Date format",
    "WIDGET_PDF_MAKER_MAIN_PLACEHOLDER_SELECT_DATE_FORMAT":"Select Date format",
    "WIDGET_PDF_MAKER_MAIN_BTN_LABEL_SAVE_SETTINGS_AND_EXPORT":"Save settings and export",
    "GLOBAL_VISIBILITY_TITLE":"Global Visibility panel",
    "GLOBAL_VISIBILITY_TITLE_TABLE_PURCHASES":"Purchases / STOCK IN",
    "GLOBAL_VISIBILITY_TITLE_TABLE_SALES":"Sales / STOCK OUT",
    "GLOBAL_VISIBILITY_TITLE_TABLE_QUOTE":"RFQ / QUOTE",
    "GLOBAL_VISIBILITY_TITLE_TABLE_STOCK":"Stock",
    "GLOBAL_VISIBILITY_PLACEHOLDER_PN":"Part Number",
    "GLOBAL_VISIBILITY_TABLE_TH_SEARCH_PART":"Parts",
    "GLOBAL_VISIBILITY_TABLE_TH_SEARCH_DESC":"Description",
    "GLOBAL_VISIBILITY_TABLE_TH_SEARCH_MFGR":"MFGR",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_1":"Created",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_2":"Status",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_3":"Deal Type",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_4":"CN",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_5":"Vendor",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_6":"Cost",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_7":"Qty",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_8":"PN",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_9":"Created By",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_10":"PO",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_1":"Created",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_2":"Status",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_3":"Deal Type",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_4":"CN",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_5":"Customer",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_6":"Price",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_7":"Qty",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_8":"PN",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_9":"Created By",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_10":"SO",
    "GLOBAL_VISIBILITY_TABLE_TH_SALES_11":"Customer PO",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_1":"Created",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_2":"Deal Type",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_3":"CN",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_4":"Company",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_5":"Price",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_6":"Qtyrec",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_7":"PN",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_8":"Created User",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_9":"Delivery",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_10":"REFNUM",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_11":"Modified Date",
    "GLOBAL_VISIBILITY_TABLE_TH_QUOTE_12":"Modified User",
    "GLOBAL_VISIBILITY_TABLE_TH_STOCK_1":"CN",
    "GLOBAL_VISIBILITY_TABLE_TH_STOCK_2":"Vendor",
    "GLOBAL_VISIBILITY_TABLE_TH_STOCK_3":"QTY",
    "GLOBAL_VISIBILITY_TABLE_TH_STOCK_4":"Bin",
    "GLOBAL_VISIBILITY_TABLE_TH_STOCK_5":"Created",
    "GLOBAL_VISIBILITY_TABLE_TH_STOCK_6":"PO",
    "GLOBAL_VISIBILITY_TEXT_CLICK_ON_LINE":"Click on line",
    "BASE_LIST_DROPDOWN_MENU_ACTION_BTN_LABEL_DELETE":"Delete",
    "BASE_LIST_VIEW_SELECTOR_PLACEHOLDER":"Select view",
    "BASE_LIST_VIEW_NAME_INPUT_PLACEHOLDER":"Name of view",
    "BASE_LIST_CONFIG_VIEWS_BTN_LABEL":"Config Views",
    "BASE_LIST_ORDER_BY_RADIO_LABEL":"Order By",
    "BASE_LIST_UPDATE_VIEW_BTN_LABEL":"Update View",
    "BASE_LIST_SET_VIEW_AS_DEFAULT_BTN_LABEL":"Set View as default",
    "BASE_LIST_SET_VIEW_AS_PRIVATE_BTN_LABEL":"Is Private",
    "BASE_LIST_CLEAR_ALL_FILTERS_BTN_LABEL":"Clear all",
    "BASE_LIST_SHOW_ALL_PICKLISTS_PLACEHOLDER":"Show all",
    "BASE_LIST_SELECT_SMTH_PLACEHOLDER":"Select",
    "BASE_LIST_SEARCH_PLACEHOLDER":"Search",
    "BASE_LIST_CREATE_VIEW_BTN_LABEL":"Create new View",
    "BASE_LIST_AVAILABLE_FIELDS_TABLE_LABEL":"Available fields",
    "BASE_LIST_SELECTED_FIELDS_TABLE_LABEL":"Fields in current config",
    "BASE_LIST_SORT_ASC_LABEL":"Asc",
    "BASE_LIST_SORT_DESC_LABEL":"Desc",
    "BASE_LIST_DELETE_ITEM_WARNING":"Really delete?",
    "BASE_PAGINATION_FROM_LABEL":"from",
    "BASE_PAGINATION_OF_LABEL":"of",
    "WIDGET_UPDATES_NO_UPDATES":"No updates yet...",
    "EDIT_FIELD_TITLE_MESSAGE_WARN":"Warning",
    "NOTIFY_TITLE_FILE_DOWNLOAD_ERR":"File download error!",
    "NOTIFY_TITLE_FILE_PRINT_ERR":"Unable to print",
    "EDIT_FIELD_PLACEHOLDER":"Edit this field",
    "TOP_SEARCH_PLACEHOLDER_LOADING":"Loading...",
    "TOP_SEARCH_PLACEHOLDER_TYPE":"Type To Search",
    "EDIT_FIELD_BUTTON_ACCEPT_ALL":"Accept All",
    "EDIT_FIELD_BUTTON_CANCEL_ALL":"Cancel All",
    "EDIT_FIELD_TEXT_MESSAGE_CONFIRM":"Do you really want to change this field?",
    "FOOTER_TEXT_POWERED_BY":"© Copyright 2021 ERP.Aero® Inc. All rights reserved",
    "FOOTER_TEXT_YEAR":"© 2012 — 2019",
    "FOOTER_TEXT_LICENSE":"Read License",
    "FOOTER_TEXT_PRIVACY":"Privacy Policy",
    "NOTIFY_TEXT_FILE_PRINT_ERR":"Please contact to administrator or <br> <a href=\"%s%\" target=\"_blank\">open file</a> in new window",
    "TOP_SEARCH_LABEL_RESULTS":"Search Results",
    "WIDGET_COMMENTS_TITLE_NOTIFY_SUCCESS":"Success",
    "WIDGET_DOCUMENTS_LABEL_FOLDER":"Folder name",
    "WIDGET_DOCUMENTS_LABEL_ASSIGNED_TO":"Assigned To",
    "WIDGET_DOCUMENTS_LABEL_FILE_NAME":"File name",
    "WIDGET_DOCUMENTS_LABEL_MODIFY_TIME":"Modify time",
    "WIDGET_DOCUMENTS_LABEL_BY_TIME":"By time",
    "WIDGET_DOCUMENTS_LABEL_BY_FILENAME":"By filename",
    "DIALOG_UPLOAD_TITLE":"Upload Documents",
    "DIALOG_UPLOAD_TITLE_NOTIFY_UPLOAD_ERR":"File upload error!",
    "DIALOG_UPLOAD_TITLE_NOTIFY_EXT_ERR":"Invalid file extension!",
    "NOTIFICATIONS_LABEL_NEW":"New",
    "NOTIFICATIONS_LABEL_AUTHOR":"Author",
    "NOTIFICATIONS_LABEL_RECIPIENT":"Recipient",
    "NOTIFICATIONS_LABEL_CREATED":"Created",
    "NOTIFICATIONS_LABEL_ACTUAL_FILTER":"Actual",
    "NOTIFICATIONS_LABEL_ARCHIVED_FILTER":"Archived",
    "NOTIFICATIONS_LABEL_DESC_DIRECTION":"Desc",
    "NOTIFICATIONS_LABEL_ASC_DIRECTION":"Asc",
    "WIDGET_COMMENTS_BUTTON_REPLY":"Reply",
    "WIDGET_COMMENTS_BUTTON_DELETE":"Delete",
    "WIDGET_COMMENTS_BUTTON_UPDATE_LINK":"Update Link",
    "WIDGET_COMMENTS_BUTTON_ADD_LINK":"Add Link",
    "WIDGET_DOCUMENTS_BUTTON_SELECT":"Select",
    "WIDGET_DOCUMENTS_BUTTON_UPLOAD":"Upload",
    "DIALOG_UPLOAD_BUTTON_ADD":"Add documents",
    "NOTIFICATIONS_BUTTON_ACK":"ACK",
    "WIDGET_SIDEBAR_BUTTON_PDF_MAKER":"PdfMaker",
    "WIDGET_SIDEBAR_BUTTON_UPDATES":"Updates",
    "WIDGET_SIDEBAR_BUTTON_DOCUMENTS":"Documents",
    "WIDGET_SIDEBAR_BUTTON_COMMENTS":"Comments",
    "WIDGET_SIDEBAR_BUTTON_NOTIFICATIONS":"Notifications",
    "WIDGET_SIDEBAR_BUTTON_DOC_NOTIFICATIONS":"Document notifications",
    "WIDGET_COMMENTS_PLACEHOLDER_WRITE":"Write a comment",
    "WIDGET_COMMENTS_PLACEHOLDER_REPLY":"Reply to",
    "WIDGET_COMMENTS_TEXT_NO_COMMENTS":"No comments",
    "WIDGET_COMMENTS_TEXT_REPLIED_TO":"Replied To",
    "WIDGET_COMMENTS_TEXT_ENTER_URL":"Enter the url of your image here",
    "WIDGET_COMMENTS_TEXT_NOTIFY_NOTIFICATION":"Notification will be sent to users",
    "WIDGET_DOCUMENTS_TEXT_NO_DATA":"No data",
    "WIDGET_DOCUMENTS_TEXT_PLEASE_WAIT":"Please wait...",
    "WIDGET_DOCUMENTS_TEXT_DOWNLOAD_LOADING":"The document is being prepared for download, please wait...",
    "DIALOG_UPLOAD_TEXT_MAX_SIZE":"Drag/Drop files here or click the area. Max size: %s% MB",
    "DIALOG_UPLOAD_TEXT_NOTIFY_UPLOAD_ERR":"Size of file %s% is more than %s%mb",
    "NOTIFICATIONS_TEXT_NO_DATA":"No data...",
    "DISCONNECT_SCREEN_TITLE":"Disconnected",
    "DISCONNECT_SCREEN_TEXT_FROM_SERVER":"from Server",
    "DISCONNECT_SCREEN_TEXT_RECCONECTION":"Reconnection",
    "DISCONNECT_SCREEN_TEXT_RECCONECTION_FAILED":"Reconnection failed",
    "WIDGET_SIDEBAR_BUTTON_OLD_VIEW":"Go to Old View",
    "WIDGET_UPDATES_SHOW_DETAILS":"Show Details",
    "DIALOG_LIST_TITLE_ACCOUNTS":"Accounts",
    "DIALOG_LIST_TITLE_CONTACTS":"Contacts",
    "DIALOG_LIST_TITLE_DOCUMENTS":"Documents",
    "DIALOG_LIST_TITLE_NOTICE":"Notice List",
    "DIALOG_LIST_TITLE_PRODUCT":"Product List",
    "DIALOG_LIST_TITLE_VENDORS":"Vendors",
    "DIALOG_LIST_TITLE_CAMPAIGNS_LIST":"Campaigns List",
    "DIALOG_LIST_TITLE_LEAD_LIST":"Lead List",
    "DIALOG_LIST_LABEL_SEARCH":"Search",
    "DIALOG_LIST_LABEL_ACCOUNT_NAME":"Account Name",
    "DIALOG_LIST_LABEL_WEBSITE":"Website",
    "DIALOG_LIST_LABEL_EMAIL":"Email",
    "DIALOG_LIST_LABEL_PHONE":"Phone",
    "DIALOG_LIST_LABEL_FIRST_NAME":"First Name",
    "DIALOG_LIST_LABEL_LAST_NAME":"Last Name",
    "DIALOG_LIST_LABEL_TITLE":"Title",
    "DIALOG_LIST_LABEL_FILE_NAME":"File Name",
    "DIALOG_LIST_LABEL_FOLDER_NAME":"Folder Name",
    "DIALOG_LIST_LABEL_ASSIGNED_TO":"Assigned To",
    "DIALOG_LIST_LABEL_CREATOR_USER":"Creator User",
    "DIALOG_LIST_LABEL_MODIFIER_USER":"Modifier User",
    "DIALOG_LIST_LABEL_NOTICE_NUMBER":"Notice Number",
    "DIALOG_LIST_LABEL_PRIORITY":"Priority",
    "DIALOG_LIST_LABEL_ACCOUNT":"Account",
    "DIALOG_LIST_LABEL_REQUESTED_BY":"Requested By",
    "DIALOG_LIST_LABEL_CREATED_DATE":"Created Date",
    "DIALOG_LIST_LABEL_PRODUCT_NAME":"Product Name",
    "DIALOG_LIST_LABEL_DESCRIPTION":"Description",
    "DIALOG_LIST_LABEL_VENDOR_NAME":"Vendor Name",
    "DIALOG_LIST_LABEL_VENDOR_NUMBER":"Vendor Number",
    "DIALOG_LIST_LABEL_CAMPAIGN_NAME":"Campaign Name",
    "DIALOG_LIST_LABEL_CAMPAIGN_STATUS":"Campaign Status",
    "DIALOG_LIST_LABEL_CAMPAIGN_TYPE":"Campaign Type",
    "DIALOG_LIST_TEXT_FROM":"from",
    "DIALOG_LIST_TEXT_OF":"of",
    "DIALOG_LIST_TABLE_TH_ACCOUNT_NAME":"Account Name",
    "DIALOG_LIST_TABLE_TH_WEBSITE":"Website",
    "DIALOG_LIST_TABLE_TH_PRIMARY_PHONE":"Primary Phone",
    "DIALOG_LIST_TABLE_TH_TERMS_SALE":"Terms of Sale",
    "DIALOG_LIST_TABLE_TH_TERMS_DELIVERY":"Terms of Delivery",
    "DIALOG_LIST_TABLE_TH_RECEIVED_VIA":"Received Via",
    "DIALOG_LIST_TABLE_TH_TERRITORY":"Territory",
    "DIALOG_LIST_TABLE_TH_FIRST_NAME":"First Name",
    "DIALOG_LIST_TABLE_TH_LAST_NAME":"Last Name",
    "DIALOG_LIST_TABLE_TH_PHONE":"Phone",
    "DIALOG_LIST_TABLE_TH_TITLE":"Title",
    "DIALOG_LIST_TABLE_TH_EMAIL":"Email",
    "DIALOG_LIST_TABLE_TH_ORDER_LIMIT":"Order approval limit",
    "DIALOG_LIST_TABLE_TH_ROLE":"Role",
    "DIALOG_LIST_TABLE_TH_FILE_NAME":"File Name",
    "DIALOG_LIST_TABLE_TH_FOLDER":"Folder",
    "DIALOG_LIST_TABLE_TH_ASSIGNED_TO":"Assigned To",
    "DIALOG_LIST_TABLE_TH_CREATOR_USER":"Creator User",
    "DIALOG_LIST_TABLE_TH_MODIFIER_USER":"Modifier User",
    "DIALOG_LIST_TABLE_TH_DUE_DATE":"Due Date",
    "DIALOG_LIST_TABLE_TH_PRIORITY":"Priority",
    "DIALOG_LIST_TABLE_TH_ACCOUNT":"Account",
    "DIALOG_LIST_TABLE_TH_REQUESTED_BY":"Requested By",
    "DIALOG_LIST_TABLE_TH_CREATED_DATE":"Requested By",
    "DIALOG_LIST_TABLE_TH_PRODUCT_NAME":"Product Name",
    "DIALOG_LIST_TABLE_TH_UNIT_PRICE":"Unit Price",
    "DIALOG_LIST_TABLE_TH_COMMISSION_RATE":"Commission rate",
    "DIALOG_LIST_TABLE_TH_SQ_RATIO":"S/Q Ratio",
    "DIALOG_LIST_TABLE_TH_DIVERSITY":"Diversity",
    "DIALOG_LIST_TABLE_TH_MOST_CD":"Most Asked Condition",
    "DIALOG_LIST_TABLE_TH_INVENTORY_ITEM":"Inventory Item",
    "DIALOG_LIST_TABLE_TH_DESCRIPTION":"Description",
    "DIALOG_LIST_TABLE_TH_VENDOR_NAME":"Vendor Name",
    "DIALOG_LIST_TABLE_TH_VENDOR_NUMBER":"Vendor Number",
    "DIALOG_LIST_TABLE_TH_PRIMARY_EMAIL":"Primary Email",
    "DIALOG_LIST_TABLE_TH_CATEGORY":"Category",
    "DIALOG_LIST_TABLE_TH_TERMS_PAYMENT":"Terms of Payment",
    "DIALOG_LIST_TABLE_TH_PLACE_DELIVERY":"Place of Delivery",
    "DIALOG_LIST_TABLE_TH_MINIMUM_ORDER_SUM":"Minimum Order Sum",
    "DIALOG_LIST_TABLE_TH_CAMPAIGN_NAME":"Campaign Name",
    "DIALOG_LIST_TABLE_TH_CAMPAIGN_STATUS":"Campaign Name",
    "DIALOG_LIST_TABLE_TH_CAMPAIGN_TYPE":"Campaign Type",
    "DIALOG_LIST_TABLE_TH_EXPECTED_CLOSE_DATE":"Expected Close Date",
    "DIALOG_LIST_TABLE_TH_EXPECTED_REVENUE":"Expected Close Date",
    "DIALOG_LIST_TABLE_TH_COMPANY":"Company",
    "DIALOG_LIST_TABLE_TH_LEAD_SOURCE":"Lead Source",
    "DIALOG_LIST_BUTTON_ADD_DOCUMENTS":"Add Documents",
    "MENU_EXPENSES":"EXPENSES",
    "MENU_TO_DO":"TODO",
    "BASE_LIST_TOO_MANY_ITEMS_TO_EXPORT_MESSAGE":"You attempt to load more then 1000 records, which will result in excessive database load. Please change your search criteria to limit number of records or send request to administrator to create special report.",
    "BASE_LIST_TOO_MANY_ITEMS_TO_EXPORT_TITLE":"Attention",
    "MENU_ALTERNATIVE":"ALTERNATIVE",
    "MENU_EVENT":"EVENTS",
    "MENU_CUSTOMER_CREDIT":"CUSTOMER CREDIT",
    "GLOBAL_VISIBILITY_TABLE_TH_STOCK_7":"Consigment",
    "GLOBAL_VISIBILITY_TABLE_TH_PURCHASES_11":"Consigment",
    "MENU_INVENTORY_ADJUSTMENT":"INVENTORY ADJUSTMENT",
    "MENU_VENDOR_OFFER":"VENDOR QUOTE",
    "GLOBAL_VISIBILITY_TITLE_CREATE_VO":"New Vendor Quote",
    "MENU_KIT":"KITS",
    "MENU_PROJECTS_AND_TASKS":"PROJECTS AND TASKS",
    "MENU_MILESTONES":"MILESTONES",
    "MENU_TASKS":"TASKS",
    "MENU_SUBTASKS":"SUBTASKS",
    "MENU_WORKLOGS":"WORKLOGS",
    "GENERATOR_QUOTE_ACCOUNT":"Account",
    "MENU_VENDOR_RETURN":"VENDOR RETURN",
    "MENU_BILL_OF_MATERIALS":"BILL OF MATERIALS",
    "GEN2_QUOTE_FIELD_QUOTE_ACCOUNT":"Account",
    "GEN2_QUOTE_FIELD_QUOTE_CONTACT":"Contact Name",
    "GEN2_QUOTE_FIELD_QUOTE_PRIORITY":"Priority",
    "GEN2_QUOTE_FIELD_QUOTE_CUST_REF":"Cust Ref",
    "GEN2_QUOTE_FIELD_QUOTE_TERM_DELIVERY":"Terms of delivery",
    "GEN2_QUOTE_FIELD_QUOTE_PLACE_DELIVERY":"Place of delivery",
    "GEN2_QUOTE_FIELD_QUOTE_TERM_SALE":"Place of sale",
    "GEN2_QUOTE_FIELD_QUOTE_QUOTED_BY":"Quoted by",
    "GEN2_QUOTE_FIELD_QUOTE_CREATED_TIME":"Created time",
    "GEN2_QUOTE_FIELD_QUOTE_MODIFIED_TIME":"Modified time",
    "GEN2_QUOTE_FIELD_QUOTE_VALID_UNTIL":"Valid Until",
    "GEN2_QUOTE_FIELD_QUOTE_OPPORTUNITY":"Opportunity",
    "GEN2_QUOTE_FIELD_QUOTE_CAMPAIGN":"Campaign",
    "GEN2_QUOTE_FIELD_QUOTE_SOURCE":"Source",
    "GEN2_QUOTE_FIELD_QUOTE_BILLING_COMPANY":"Billing Company",
    "GEN2_QUOTE_FIELD_QUOTE_BILLING_ADDRESS":"Billing Address 1",
    "GEN2_QUOTE_FIELD_QUOTE_BILLING_ADDRESS_SECOND":"Billing Address 2",
    "GEN2_QUOTE_FIELD_QUOTE_BILLING_CITY":"Billing City",
    "GEN2_QUOTE_FIELD_QUOTE_BILLING_PROVINCE":"Billing Province/State",
    "GEN2_QUOTE_FIELD_QUOTE_BILLING_POSTAL_CODE":"Billing Postal Code",
    "GEN2_QUOTE_FIELD_QUOTE_BILLING_COUNTRY":"Billing Country",
    "GEN2_QUOTE_FIELD_QUOTE_SHIPPING_COMPANY":"Shipping Company",
    "GEN2_QUOTE_FIELD_QUOTE_SHIPPING_ADDRESS_FIRST":"Shipping Address 1",
    "GEN2_QUOTE_FIELD_QUOTE_SHIPPING_ADDRESS_SECOND":"Shipping Address 2",
    "GEN2_QUOTE_FIELD_QUOTE_SHIPPING_CITY":"Shipping City",
    "GEN2_QUOTE_FIELD_QUOTE_SHIPPING_PROVINCE":"Shipping Province/State",
    "GEN2_QUOTE_FIELD_QUOTE_SHIPPING_POSTAL_CODE":"Shipping Postal Code",
    "GEN2_QUOTE_FIELD_QUOTE_SHIPPING_COUNTRY":"Shipping Country",
    "GEN2_QUOTE_FIELD_QUOTE_FINAL_COMPANY":"Final Company",
    "GEN2_QUOTE_FIELD_QUOTE_FINAL_ADDRESS_FIRST":"Final Address 1",
    "GEN2_QUOTE_FIELD_QUOTE_FINAL_ADDRESS_SECOND":"Final Address 2",
    "GEN2_QUOTE_FIELD_QUOTE_FINAL_CITY":"Final City",
    "GEN2_QUOTE_FIELD_QUOTE_FINAL_PROVINCE":"Final Province/State",
    "GEN2_QUOTE_FIELD_QUOTE_FINAL_POSTAL_CODE":"Final Postal Code",
    "GEN2_QUOTE_FIELD_QUOTE_FINAL_COUNTRY":"Final Country",
    "GEN2_QUOTE_QUOTE_BIDS_PN":"Part Number",
    "GEN2_QUOTE_QUOTE_BIDS_BY_COMPANY":"Bid By Company",
    "GEN2_QUOTE_QUOTE_BIDS_CONDITION":"Condition",
    "GEN2_QUOTE_QUOTE_BIDS_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_QUOTE_BIDS_WINNING_BID":"Winning Bid",
    "GEN2_QUOTE_QUOTE_BIDS_TARGET_PRICE":"",
    "GEN2_QUOTE_QUOTE_BIDS_BID_PRICE":"Bid Price",
    "GEN2_QUOTE_QUOTE_BIDS_NOTES":"Notes",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_NO":"Line no",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_STATE":"State",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_PN":"Line PN",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_DESC":"Description",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_NOTES":"Line Notes",
    "GEN2_QUOTE_FIELD_QUOTE_LINES_MOQ":"MOQ",
    "GEN2_QUOTE_FIELD_QUOTE_CERT_TYPE":"Cert Type",
    "GEN2_QUOTE_FIELD_QUOTE_CERT_TRACE":"Trace",
    "GEN2_QUOTE_FIELD_QUOTE_CERT_DATE":"Tag Date",
    "GEN2_QUOTE_FIELD_QUOTE_CERT_SHOP":"Shop",
    "GEN2_QUOTE_FIELD_QUOTE_CERT_SN":"S/N",
    "GEN2_QUOTE_FIELD_QUOTE_CERT_WARRANTY":"Warranty",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_CONDITION":"Condition",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_DELIVERY":"Delivery",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_QTY":"QTY",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_UNIT_PRICE":"Unit Price",
    "GEN2_QUOTE_FIELD_QUOTE_LINE_DISCOUNT":"Discount",
    "GEN2_QUOTE_FIELD_QUOTE_CURRENCY":"Currency",
    "GEN2_QUOTE_FIELD_QUOTE_RATE":"Rate",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_TERMS":"Terms & Conditions",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_DETAILS_NOTES":"Notes",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_DETAILS_DESCRIPTION":"Description",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_MISC_STAGE":"Quote stage",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_MISC_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_MISC_CREATOR":"Creator",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_MISC_TERRITORY":"Territory",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_MISC_LOCATION":"Location",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_MISC_NUMBER":"Quote Number",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_EXP_DATE":"Expiration Date",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_AIRCRAFT":"Aircraft",
    "GEN2_QUOTE_FIELD_QUOTE_TAB_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_FIELD_QUOTE_FIN_SYNC_QB":"Sync to QB",
    "GEN2_QUOTE_FIELD_QUOTE_FIN_SENT_QB":"Sent to QB",
    "GEN2_QUOTE_FIELD_QUOTE_FIN_COMPANY_QB":"Company QB",
    "GEN2_QUOTE_FIELD_QUOTE_FIN_EMAIL_LATER":"Email later",
    "GEN2_QUOTE_FIELD_QUOTE_FIN_JOB":"Job",
    "GEN2_QUOTE_FIELD_QUOTE_FIN_QB_NO":"QB No",
    "GEN2_QUOTE_FIELD_QUOTE_FIN_QB_CLASS":"QB Class",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_0":"",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_1":"Part Number",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_2":"Bid by Company",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_3":"Condition",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_4":"Sales Type",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_5":"Target Price",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_6":"Winning Bid",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_7":"Bid Price",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_BIDS_LINES_8":"Notes",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_0":"Item#",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_1":"Part Number / Description",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_2":"Certificate",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_3":"Type",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_4":"CD",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_5":"Delivery",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_6":"QTY & UOM",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_7":"Unit price",
    "GEN2_QUOTE_BLOCK_BLOCK_QUOTE_TAB_DETAILS_LINES_8":"Total line",
    "GLOBAL_VISIBILITY_TABLE_TH_SEARCH_I_CHANGE_A":"Interchangeability",
    "GLOBAL_VISIBILITY_TABLE_TH_SEARCH_PMA":"PMA",
    "GLOBAL_VISIBILITY_TABLE_TH_SEARCH_CONFIRMED":"Confirmed",
    "WIDGET_PDF_MAKER_SALESTYPE_SERVICE":"Service",
    "MENU_IMPORTS":"IMPORTS",
    "MENU_IMPORT_PARTS":"IMPORT PARTS",
    "MENU_IMPORT_COMPANY":"IMPORT COMPANIES",
    "MENU_IMPORT_CONTACTS":"IMPORT CONTACTS",
    "MENU_IMPORT_LIST":"ALL IMPORTS",
    "MENU_IMPORT_BANK_ACCOUNT":"IMPORT BANK ACCOUNT",
    "MENU_IMPORT_SO":"IMPORT SO",
    "MENU_IMPORT_PO":"IMPORT PO",
    "MENU_IMPORT_QUOTE":"IMPORT QUOTE",
    "MENU_IMPORT_VENDOR_RETURN":"IMPORT VENDOR RETURN",
    "MENU_IMPORT_EMPLOYEE":"IMPORT EMPLOYEE",
    "MENU_USERS":"USERS",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_ACCOUNT":"Account",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BIDS_BID_PRICE":"Bid Price",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BIDS_BY_COMPANY":"Bid By Company",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BIDS_CONDITION":"Condition",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BIDS_NOTES":"Notes",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BIDS_PN":"Part Number",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BIDS_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BIDS_TARGET_PRICE":"",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BIDS_WINNING_BID":"Winning Bid",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BILLING_ADDRESS":"Billing Address 1",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BILLING_ADDRESS_SECOND":"Billing Address 2",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BILLING_CITY":"Billing City",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BILLING_COMPANY":"Billing Company",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BILLING_COUNTRY":"Billing Country",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BILLING_POSTAL_CODE":"Billing Postal Code",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_BILLING_PROVINCE":"Billing Province/State",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CAMPAIGN":"Campaign",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CERT_DATE":"Tag Date",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CERT_SHOP":"Shop",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CERT_SN":"S/N",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CERT_TRACE":"Trace",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CERT_TYPE":"Cert Type",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CERT_WARRANTY":"Warranty",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CONTACT":"Contact Name",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CREATED_TIME":"Created time",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CURRENCY":"Currency",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_CUST_REF":"Cust Ref",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FINAL_ADDRESS_FIRST":"Final Address 1",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FINAL_ADDRESS_SECOND":"Final Address 2",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FINAL_CITY":"Final City",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FINAL_COMPANY":"Final Company",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FINAL_COUNTRY":"Final Country",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FINAL_POSTAL_CODE":"Final Postal Code",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FINAL_PROVINCE":"Final Province/State",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FIN_COMPANY_QB":"Company QB",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FIN_EMAIL_LATER":"Email later",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FIN_JOB":"Job",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FIN_QB_CLASS":"QB Class",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FIN_QB_NO":"QB No",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FIN_SENT_QB":"Sent to QB",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_FIN_SYNC_QB":"Sync to QB",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINES_MOQ":"MOQ",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_CONDITION":"Condition",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_DELIVERY":"Delivery",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_DESC":"Description",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_DISCOUNT":"Discount",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_NO":"Line no",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_NOTES":"Line Notes",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_PN":"Line PN",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_QTY":"QTY",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_STATE":"State",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_LINE_UNIT_PRICE":"Unit Price",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_MODIFIED_TIME":"Modified time",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_OPPORTUNITY":"Opportunity",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_PLACE_DELIVERY":"Place of delivery",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_PRIORITY":"Priority",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_QUOTED_BY":"Quoted by",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_RATE":"Rate",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_SHIPPING_ADDRESS_FIRST":"Shipping Address 1",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_SHIPPING_ADDRESS_SECOND":"Shipping Address 2",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_SHIPPING_CITY":"Shipping City",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_SHIPPING_COMPANY":"Shipping Company",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_SHIPPING_COUNTRY":"Shipping Country",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_SHIPPING_POSTAL_CODE":"Shipping Postal Code",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_SHIPPING_PROVINCE":"Shipping Province/State",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_SOURCE":"Source",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_AIRCRAFT":"Aircraft",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_DETAILS_DESCRIPTION":"Description",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_DETAILS_NOTES":"Notes",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_EXP_DATE":"Expiration Date",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_MISC_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_MISC_CREATOR":"Creator",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_MISC_LOCATION":"Location",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_MISC_NUMBER":"Quote Number",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_MISC_STAGE":"Quote stage",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_MISC_TERRITORY":"Territory",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TAB_TERMS":"Terms & Conditions",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TERM_DELIVERY":"Terms of delivery",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_TERM_SALE":"Place of sale",
    "GEN2_QUOTE_CORE_FIELD_QUOTE_VALID_UNTIL":"Valid Until",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_ACCOUNT":"Account",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BILLING_ADDRESS":"Billing Address 1",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BILLING_ADDRESS_SECOND":"Billing Address 2",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BILLING_CITY":"Billing City",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BILLING_COMPANY":"Billing Company",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BILLING_COUNTRY":"Billing Country",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BILLING_POSTAL_CODE":"Billing Postal Code",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BILLING_PROVINCE":"Billing Province/State",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CAMPAIGN":"Campaign",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CERT_DATE":"Tag Date",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CERT_SHOP":"Shop",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CERT_SN":"S/N",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CERT_TRACE":"Trace",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CERT_TYPE":"Cert Type",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CERT_WARRANTY":"Warranty",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CONTACT":"Contact Name",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CREATED_TIME":"Created time",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CURRENCY":"Currency",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_CUST_REF":"Cust Ref",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FINAL_ADDRESS_FIRST":"Final Address 1",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FINAL_ADDRESS_SECOND":"Final Address 2",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FINAL_CITY":"Final City",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FINAL_COMPANY":"Final Company",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FINAL_COUNTRY":"Final Country",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FINAL_POSTAL_CODE":"Final Postal Code",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FINAL_PROVINCE":"Final Province/State",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FIN_COMPANY_QB":"Company QB",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FIN_EMAIL_LATER":"Email later",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FIN_JOB":"Job",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FIN_QB_CLASS":"QB Class",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FIN_QB_NO":"QB No",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FIN_SENT_QB":"Sent to QB",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_FIN_SYNC_QB":"Sync to QB",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINES_MOQ":"MOQ",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_CONDITION":"Condition",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_DELIVERY":"Delivery",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_DESC":"Description",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_DISCOUNT":"Discount",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_NO":"Line no",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_NOTES":"Line Notes",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_PN":"Line PN",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_QTY":"QTY",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_STATE":"State",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_LINE_UNIT_PRICE":"Unit Price",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_MODIFIED_TIME":"Modified time",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_OPPORTUNITY":"Opportunity",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_PLACE_DELIVERY":"Place of delivery",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_PRIORITY":"Priority",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_QUOTED_BY":"Quoted by",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_RATE":"Rate",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_SHIPPING_ADDRESS_FIRST":"Shipping Address 1",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_SHIPPING_ADDRESS_SECOND":"Shipping Address 2",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_SHIPPING_CITY":"Shipping City",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_SHIPPING_COMPANY":"Shipping Company",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_SHIPPING_COUNTRY":"Shipping Country",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_SHIPPING_POSTAL_CODE":"Shipping Postal Code",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_SHIPPING_PROVINCE":"Shipping Province/State",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_SOURCE":"Source",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_AIRCRAFT":"Aircraft",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_DETAILS_DESCRIPTION":"Description",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_DETAILS_NOTES":"Notes",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_EXP_DATE":"Expiration Date",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_MISC_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_MISC_CREATOR":"Creator",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_MISC_LOCATION":"Location",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_MISC_NUMBER":"Quote Number",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_MISC_STAGE":"Quote stage",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_MISC_TERRITORY":"Territory",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TAB_TERMS":"Terms & Conditions",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TERM_DELIVERY":"Terms of delivery",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_TERM_SALE":"Place of sale",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_VALID_UNTIL":"Valid Until",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BIDS_BID_PRICE":"Bid Price",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BIDS_BY_COMPANY":"Bid By Company",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BIDS_CONDITION":"Condition",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BIDS_NOTES":"Notes",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BIDS_PN":"Part Number",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BIDS_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BIDS_TARGET_PRICE":"",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATE_BIDS_WINNING_BID":"Winning Bid",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_ACCOUNT":"Account",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_BID_PRICE":"Bid Price",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_BY_COMPANY":"Bid By Company",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_CONDITION":"Condition",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_NOTES":"Notes",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_PN":"Part Number",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_TARGET_PRICE":"",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_WINNING_BID":"Winning Bid",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BILLING_ADDRESS":"Billing Address 1",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BILLING_ADDRESS_SECOND":"Billing Address 2",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BILLING_CITY":"Billing City",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BILLING_COMPANY":"Billing Company",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BILLING_COUNTRY":"Billing Country",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BILLING_POSTAL_CODE":"Billing Postal Code",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BILLING_PROVINCE":"Billing Province/State",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CAMPAIGN":"Campaign",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CERT_DATE":"Tag Date",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CERT_SHOP":"Shop",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CERT_SN":"S/N",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CERT_TRACE":"Trace",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CERT_TYPE":"Cert Type",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CERT_WARRANTY":"Warranty",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CONTACT":"Contact Name",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CREATED_TIME":"Created time",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CURRENCY":"Currency",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CUST_REF":"Cust Ref",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FINAL_ADDRESS_FIRST":"Final Address 1",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FINAL_ADDRESS_SECOND":"Final Address 2",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FINAL_CITY":"Final City",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FINAL_COMPANY":"Final Company",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FINAL_COUNTRY":"Final Country",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FINAL_POSTAL_CODE":"Final Postal Code",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FINAL_PROVINCE":"Final Province/State",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FIN_COMPANY_QB":"Company QB",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FIN_EMAIL_LATER":"Email later",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FIN_JOB":"Job",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FIN_QB_CLASS":"QB Class",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FIN_QB_NO":"QB No",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FIN_SENT_QB":"Sent to QB",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_FIN_SYNC_QB":"Sync to QB",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINES_MOQ":"MOQ",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_CONDITION":"Condition",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_DELIVERY":"Delivery",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_DESC":"Description",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_DISCOUNT":"Discount",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_NO":"Line no",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_NOTES":"Line Notes",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_PN":"Line PN",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_QTY":"QTY",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_STATE":"State",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_UNIT_PRICE":"Unit Price",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_MODIFIED_TIME":"Modified time",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_OPPORTUNITY":"Opportunity",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_PLACE_DELIVERY":"Place of delivery",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_PRIORITY":"Priority",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_QUOTED_BY":"Quoted by",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_RATE":"Rate",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_SHIPPING_ADDRESS_FIRST":"Shipping Address 1",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_SHIPPING_ADDRESS_SECOND":"Shipping Address 2",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_SHIPPING_CITY":"Shipping City",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_SHIPPING_COMPANY":"Shipping Company",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_SHIPPING_COUNTRY":"Shipping Country",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_SHIPPING_POSTAL_CODE":"Shipping Postal Code",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_SHIPPING_PROVINCE":"Shipping Province/State",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_SOURCE":"Source",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_AIRCRAFT":"Aircraft",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_DETAILS_DESCRIPTION":"Description",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_DETAILS_NOTES":"Notes",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_EXP_DATE":"Expiration Date",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_MISC_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_MISC_CREATOR":"Created By",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_MISC_LOCATION":"Location",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_MISC_NUMBER":"Quote Number",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_MISC_STAGE":"Quote stage",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_MISC_TERRITORY":"Territory",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TAB_TERMS":"Terms & Conditions",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TERM_DELIVERY":"Terms of delivery",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_TERM_SALE":"Terms of sale",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_VALID_UNTIL":"Valid Until",
    "GEN2_QUOTE_quote_details_CORE_FIELD_TEST":"TEST",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_ACCOUNT":"Account",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_BID_PRICE":"Bid Price",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_BY_COMPANY":"Bid By Company",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_CONDITION":"Condition",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_NOTES":"Notes",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_PN":"Part Number",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_TARGET_PRICE":"",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_WINNING_BID":"Winning Bid",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BILLING_ADDRESS":"Billing Address 1",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BILLING_ADDRESS_SECOND":"Billing Address 2",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BILLING_CITY":"Billing City",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BILLING_COMPANY":"Billing Company",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BILLING_COUNTRY":"Billing Country",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BILLING_POSTAL_CODE":"Billing Postal Code",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BILLING_PROVINCE":"Billing Province/State",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CAMPAIGN":"Campaign",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CERT_DATE":"Tag Date",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CERT_SHOP":"Shop",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CERT_SN":"S/N",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CERT_TRACE":"Trace",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CERT_TYPE":"Cert Type",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CERT_WARRANTY":"Warranty",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CONTACT":"Contact Name",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CREATED_TIME":"Created time",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CURRENCY":"Currency",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CUST_REF":"Cust Ref",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FINAL_ADDRESS_FIRST":"Final Address 1",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FINAL_ADDRESS_SECOND":"Final Address 2",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FINAL_CITY":"Final City",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FINAL_COMPANY":"Final Company",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FINAL_COUNTRY":"Final Country",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FINAL_POSTAL_CODE":"Final Postal Code",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FINAL_PROVINCE":"Final Province/State",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FIN_COMPANY_QB":"Company QB",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FIN_EMAIL_LATER":"Email later",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FIN_JOB":"Job",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FIN_QB_CLASS":"QB Class",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FIN_QB_NO":"QB No",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FIN_SENT_QB":"Sent to QB",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_FIN_SYNC_QB":"Sync to QB",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINES_MOQ":"MOQ",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_CONDITION":"Condition",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_DELIVERY":"Delivery",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_DESC":"Description",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_DISCOUNT":"Discount",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_NO":"Line no",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_NOTES":"Line Notes",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_PN":"Line PN",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_QTY":"QTY",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_STATE":"State",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_UNIT_PRICE":"Unit Price",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_MODIFIED_TIME":"Modified time",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_OPPORTUNITY":"Opportunity",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_PLACE_DELIVERY":"Place of delivery",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_PRIORITY":"Priority",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_QUOTED_BY":"Quoted by",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_RATE":"Rate",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_SHIPPING_ADDRESS_FIRST":"Shipping Address 1",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_SHIPPING_ADDRESS_SECOND":"Shipping Address 2",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_SHIPPING_CITY":"Shipping City",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_SHIPPING_COMPANY":"Shipping Company",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_SHIPPING_COUNTRY":"Shipping Country",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_SHIPPING_POSTAL_CODE":"Shipping Postal Code",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_SHIPPING_PROVINCE":"Shipping Province/State",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_SOURCE":"Source",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_AIRCRAFT":"Aircraft",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_DETAILS_DESCRIPTION":"Description",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_DETAILS_NOTES":"Notes",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_EXP_DATE":"Expiration Date",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_MISC_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_MISC_CREATOR":"Created By",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_MISC_LOCATION":"Location",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_MISC_NUMBER":"Quote Number",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_MISC_STAGE":"Quote stage",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_MISC_TERRITORY":"Territory",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TAB_TERMS":"Terms & Conditions",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TERM_DELIVERY":"Terms of delivery",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_TERM_SALE":"Terms of sale",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_VALID_UNTIL":"Valid Until",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_BIDS_PN_Q":"PN Quoted",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_BIDS_PN_Q":"PN Quoted",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_VERSION":"Version",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_VERSION":"Version",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_CONTRACT":"Contract",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_CONTRACT":"Contract",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_CUST_QUOTE":"Customer quote",
    "GEN2_RFQ_CORE_FIELD_RFQ_ACCOUNT":"Account",
    "GEN2_RFQ_CORE_FIELD_RFQ_CONTACT":"Contact Name",
    "GEN2_RFQ_CORE_FIELD_RFQ_PLACE_DELIVERY":"Place of delivery",
    "GEN2_RFQ_CORE_FIELD_RFQ_PRIORITY":"Priority",
    "GEN2_RFQ_CORE_FIELD_RFQ_SOURCE":"Source",
    "GEN2_RFQ_CORE_FIELD_RFQ_TAB_AIRCRAFT":"Aircraft",
    "GEN2_RFQ_CORE_FIELD_RFQ_TAB_ASSIGNED_TO":"Assigned To",
    "GEN2_RFQ_CORE_FIELD_RFQ_TAB_EXP_DATE":"Expiration Date",
    "GEN2_RFQ_CORE_FIELD_RFQ_TAB_MISC_LOCATION":"Location",
    "GEN2_RFQ_CORE_FIELD_RFQ_TAB_MISC_TERRITORY":"Territory",
    "GEN2_RFQ_CORE_FIELD_RFQ_TERM_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_CORE_FIELD_RFQ_TERM_SALE":"Terms of sale",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_ACCOUNT":"Account",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_CONTACT":"Contact name",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_PLACE_DELIVERY":"Place of delivery",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_PRIORITY":"Priority",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_SOURCE":"Source",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_TERM_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_TERM_SALE":"Terms of sale",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_AIRCRAFT":"Aircraft",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_ASSIGNED_TO":"Assigned to",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_EXP_DATE":"Expiration date",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LOCATION":"Location",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_TERRITORY":"Territory",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_REQ_DATE":"Request date",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_PROCESSED_BY":"Processed by",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_RECEIVED_VIA":"Received via",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_CURRENCY":"Currency",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_CURRENCY_RATE":"Rate",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_NUMBER":"RFQ number",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_ACCOUNT":"Account",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_AIRCRAFT":"Aircraft",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_ASSIGNED_TO":"Assigned to",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_CONTACT":"Contact name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_CURRENCY":"Currency",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_CURRENCY_RATE":"Rate",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_CUST_QUOTE":"Customer quote",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_EXP_DATE":"Expiration date",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LOCATION":"Location",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_NUMBER":"RFQ number",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_PLACE_DELIVERY":"Place of delivery",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_PRIORITY":"Priority",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_PROCESSED_BY":"Processed by",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_RECEIVED_VIA":"Received via",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_REQ_DATE":"Request date",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_SOURCE":"Source",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_TERM_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_TERM_SALE":"Terms of sale",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_TERRITORY":"Territory",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PN_REQ":"PN req",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_STATUS":"Line status",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_NSN":"NSN",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_CAGE_CODE":"Cage code",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_SALES_TYPE":"Sales type",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_QTY_REQ":"QTY req",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_BASE_QTY_REQ":"Base QTY req",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_UOM":"UOM",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_BASE_UOM":"Base UOM",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_CONDITION":"Condition",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_SAVED_PRICE":"Saved price",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_NAME":"Name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_PHONE":"Phone",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_EMAIL":"Email",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_FAX":"Fax",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_COUNTRY":"Country",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_STATE":"State",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_CITY":"City",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_ADDRESS":"Address",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_RATING":"Rating",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_TERMS_PAYMENT":"Terms of payment",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_TERMS_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_VCI_VENDOR_CODE":"Vendor code",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_DELIVERY_BY_CHECK":"",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_PRICE_BY_CHECK":"",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_PRICE_MAX":"",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_RETURN_CORE":"",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_EXCHANGE_BER":"",
    "GEN2_QUOTE_quote_details_CORE_FIELD_QUOTE_LINE_REPAIR":"",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_ACCOUNT":"Account",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_BID_PRICE":"Bid Price",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_BY_COMPANY":"Bid By Company",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_CONDITION":"Condition",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_NOTES":"Notes",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_PN":"Part Number",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_PN_Q":"PN Quoted",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_TARGET_PRICE":"",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BIDS_WINNING_BID":"Winning Bid",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BILLING_ADDRESS":"Billing Address 1",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BILLING_ADDRESS_SECOND":"Billing Address 2",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BILLING_CITY":"Billing City",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BILLING_COMPANY":"Billing Company",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BILLING_COUNTRY":"Billing Country",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BILLING_POSTAL_CODE":"Billing Postal Code",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_BILLING_PROVINCE":"Billing Province/State",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CAMPAIGN":"Campaign",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CERT_DATE":"Tag Date",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CERT_SHOP":"Shop",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CERT_SN":"S/N",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CERT_TRACE":"Trace",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CERT_TYPE":"Cert Type",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CERT_WARRANTY":"Warranty",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CONTACT":"Contact Name",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CONTRACT":"Contract",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CREATED_TIME":"Created time",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CURRENCY":"Currency",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_CUST_REF":"Cust Ref",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FINAL_ADDRESS_FIRST":"Final Address 1",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FINAL_ADDRESS_SECOND":"Final Address 2",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FINAL_CITY":"Final City",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FINAL_COMPANY":"Final Company",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FINAL_COUNTRY":"Final Country",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FINAL_POSTAL_CODE":"Final Postal Code",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FINAL_PROVINCE":"Final Province/State",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FIN_COMPANY_QB":"Company QB",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FIN_EMAIL_LATER":"Email later",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FIN_JOB":"Job",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FIN_QB_CLASS":"QB Class",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FIN_QB_NO":"QB No",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FIN_SENT_QB":"Sent to QB",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_FIN_SYNC_QB":"Sync to QB",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINES_MOQ":"MOQ",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_CONDITION":"Condition",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_DELIVERY":"Delivery",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_DELIVERY_BY_CHECK":"",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_DESC":"Description",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_DISCOUNT":"Discount",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_EXCHANGE_BER":"",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_NO":"Line no",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_NOTES":"Line Notes",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_PN":"Line PN",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_PRICE_BY_CHECK":"",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_PRICE_MAX":"",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_QTY":"QTY",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_REPAIR":"",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_RETURN_CORE":"",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_SALES_TYPE":"Sales Type",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_STATE":"State",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_LINE_UNIT_PRICE":"Unit Price",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_MODIFIED_TIME":"Modified time",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_OPPORTUNITY":"Opportunity",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_PLACE_DELIVERY":"Place of delivery",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_PRIORITY":"Priority",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_QUOTED_BY":"Quoted by",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_RATE":"Rate",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_SHIPPING_ADDRESS_FIRST":"Shipping Address 1",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_SHIPPING_ADDRESS_SECOND":"Shipping Address 2",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_SHIPPING_CITY":"Shipping City",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_SHIPPING_COMPANY":"Shipping Company",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_SHIPPING_COUNTRY":"Shipping Country",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_SHIPPING_POSTAL_CODE":"Shipping Postal Code",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_SHIPPING_PROVINCE":"Shipping Province/State",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_SOURCE":"Source",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_AIRCRAFT":"Aircraft",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_DETAILS_DESCRIPTION":"Description",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_DETAILS_NOTES":"Notes",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_EXP_DATE":"Expiration Date",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_MISC_ASSIGNED_TO":"Assigned To",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_MISC_CREATOR":"Created By",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_MISC_LOCATION":"Location",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_MISC_NUMBER":"Quote Number",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_MISC_STAGE":"Quote stage",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_MISC_TERRITORY":"Territory",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TAB_TERMS":"Terms & Conditions",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TERM_DELIVERY":"Terms of delivery",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_TERM_SALE":"Terms of sale",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_VALID_UNTIL":"Valid Until",
    "GEN2_QUOTE_quote_list_CORE_FIELD_QUOTE_VERSION":"Version",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_DELIVERY_BY_CHECK":"",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_EXCHANGE_BER":"",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_PRICE_BY_CHECK":"",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_PRICE_MAX":"",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_REPAIR":"",
    "GEN2_QUOTE_quote_create_CORE_FIELD_QUOTE_LINE_RETURN_CORE":"",
    "GEN2_TEST_test_details_CORE_FIELD_TEST_DETAILS_STAGE":"",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_BASE_QTY_REQ":"Base QTY req",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_BASE_UOM":"Base UOM",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_CAGE_CODE":"Cage code",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_CONDITION":"Condition",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_NSN":"NSN",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PN_REQ":"PN req",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_QTY_REQ":"QTY req",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_SALES_TYPE":"Sales type",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_SAVED_PRICE":"Saved price",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_STATUS":"Line status",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_UOM":"UOM",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_ADDRESS":"Address",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_CITY":"City",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_COUNTRY":"Country",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_EMAIL":"Email",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_FAX":"Fax",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_NAME":"Name",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_PHONE":"Phone",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_RATING":"Rating",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_STATE":"State",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_TERMS_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_TERMS_PAYMENT":"Terms of payment",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_VCI_VENDOR_CODE":"Vendor code",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_PARTS_STATUS":"",
    "GEN2_TEST_test_information_CORE_FIELD_TEST_DETAILS_STAGE":"",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_PARTS_STATUS":"",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_ACCOUNT":"Account",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_AIRCRAFT":"Aircraft",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_ASSIGNED_TO":"Assigned to",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_CONTACT":"Contact name",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_CURRENCY":"Currency",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_CURRENCY_RATE":"Rate",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_CUST_QUOTE":"Customer quote",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_EXP_DATE":"Expiration date",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_BASE_QTY_REQ":"Base QTY req",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_BASE_UOM":"Base UOM",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_CAGE_CODE":"Cage code",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_CONDITION":"Condition",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_NSN":"NSN",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_PN_REQ":"PN req",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_QTY_REQ":"QTY req",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_SALES_TYPE":"Sales type",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_SAVED_PRICE":"Saved price",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_STATUS":"Line status",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_UOM":"UOM",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_ADDRESS":"Address",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_CITY":"City",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_COUNTRY":"Country",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_EMAIL":"Email",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_FAX":"Fax",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_NAME":"Name",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_PHONE":"Phone",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_RATING":"Rating",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_STATE":"State",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_TERMS_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_TERMS_PAYMENT":"Terms of payment",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LINE_VCI_VENDOR_CODE":"Vendor code",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_LOCATION":"Location",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_NUMBER":"RFQ number",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_PARTS_STATUS":"",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_PLACE_DELIVERY":"Place of delivery",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_PRIORITY":"Priority",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_PROCESSED_BY":"Processed by",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_RECEIVED_VIA":"Received via",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_REQ_DATE":"Request date",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_SOURCE":"Source",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_TERM_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_TERM_SALE":"Terms of sale",
    "GEN2_RFQ_rfq_list_CORE_FIELD_RFQ_TERRITORY":"Territory",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_PN_AVAIL":"PN avail",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_SALES_TYPE":"Sales type",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_TRACE":"Trace",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_WARRANTY":"Warranty",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_SHOP":"Shop",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_SN":"S/N",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_HAZMAT":"Hazmat",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_RATE":"Rate",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_QTY_AVAIL":"QTY avail",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_UOM":"UoM",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_CONDITION":"Condition",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_CERT_TYPE":"Cert type",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_TAG_DATE":"Tag Date",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PLI_LOCATION":"Location",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_CERT_TYPE":"Cert type",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_CONDITION":"Condition",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_HAZMAT":"Hazmat",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_LOCATION":"Location",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_PN_AVAIL":"PN avail",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_QTY_AVAIL":"QTY avail",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_RATE":"Rate",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_SALES_TYPE":"Sales type",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_SHOP":"Shop",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_SN":"S/N",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_TAG_DATE":"Tag Date",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_TRACE":"Trace",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_UOM":"UoM",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PLI_WARRANTY":"Warranty",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_COMPANY":"Company",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_COMPANY":"Company",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_U_COST":"U.Cost",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_VENDOR_MOQ":"Vendor MOQ",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_VENDOR_QTY":"Vendor QTY",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_TOTAL_COST":"Total cost",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_MIN_VENDOR_ORDER":"Min vendor order",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_LEAD_TIME":"Lead time",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_MGM":"MGM",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_U_PRICE":"U.Price",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_CUST_MOQ":"MOQ to customer",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_CUST_QTY":"QTY to customer",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_TOTAL_PRICE":"Total price",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_DELIVERY_TIME":"Delivery time",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_MGM_PERCENT":"MGM (%)",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_CUST_MOQ":"MOQ to customer",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_CUST_QTY":"QTY to customer",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_DELIVERY_TIME":"Delivery time",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_LEAD_TIME":"Lead time",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_MGM":"MGM",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_MGM_PERCENT":"MGM (%)",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_MIN_VENDOR_ORDER":"Min vendor order",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_TOTAL_COST":"Total cost",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_TOTAL_PRICE":"Total price",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_U_COST":"U.Cost",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_U_PRICE":"U.Price",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_VENDOR_MOQ":"Vendor MOQ",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_VENDOR_QTY":"Vendor QTY",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_AIRCRAFT":"Aircraft",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_ASSIGNED_TO":"Assigned to",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_COMPANY":"Company",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_CONTACT":"Contact name",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_CURRENCY":"Currency",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_CURRENCY_RATE":"Rate",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_CUST_QUOTE":"Customer quote",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_EXP_DATE":"Expiration date",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_BASE_QTY_REQ":"Base QTY req",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_BASE_UOM":"Base UOM",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_CAGE_CODE":"Cage code",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_CONDITION":"Condition",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_NSN":"NSN",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_CERT_TYPE":"Cert type",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_CONDITION":"Condition",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_HAZMAT":"Hazmat",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_LOCATION":"Location",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_PN_AVAIL":"PN avail",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_QTY_AVAIL":"QTY avail",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_RATE":"Rate",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_SALES_TYPE":"Sales type",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_SHOP":"Shop",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_SN":"S/N",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_TAG_DATE":"Tag Date",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_TRACE":"Trace",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_UOM":"UoM",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PLI_WARRANTY":"Warranty",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PN_REQ":"PN req",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_CUST_MOQ":"MOQ to customer",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_CUST_QTY":"QTY to customer",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_DELIVERY_TIME":"Delivery time",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_LEAD_TIME":"Lead time",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_MGM":"MGM",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_MGM_PERCENT":"MGM (%)",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_MIN_VENDOR_ORDER":"Min vendor order",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_TOTAL_COST":"Total cost",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_TOTAL_PRICE":"Total price",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_U_COST":"U.Cost",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_U_PRICE":"U.Price",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_VENDOR_MOQ":"Vendor MOQ",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_VENDOR_QTY":"Vendor QTY",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_QTY_REQ":"QTY req",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_SALES_TYPE":"Sales type",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_SAVED_PRICE":"Saved price",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_STATUS":"Line status",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_UOM":"UOM",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_ADDRESS":"Address",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_CITY":"City",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_COUNTRY":"Country",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_EMAIL":"Email",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_FAX":"Fax",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_NAME":"Name",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_PHONE":"Phone",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_RATING":"Rating",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_STATE":"State",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_TERMS_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_TERMS_PAYMENT":"Terms of payment",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_VCI_VENDOR_CODE":"Vendor code",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LOCATION":"Location",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_NUMBER":"RFQ number",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_PARTS_STATUS":"",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_PLACE_DELIVERY":"Place of delivery",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_PRIORITY":"Priority",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_PROCESSED_BY":"Processed by",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_RECEIVED_VIA":"Received via",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_REQ_DATE":"Request date",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_SOURCE":"Source",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_TERM_DELIVERY":"Terms of delivery",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_TERM_SALE":"Terms of sale",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_TERRITORY":"Territory",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_PMA":"PMA",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_ALT_PN":"Alt PN",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_APPROVAL":"Approval",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_DOCUMENTS":"Documents",
    "GEN2_PARTS_ALTERNATIVE_ALT_PRODUCTNAME":"ALT PN",
    "GEN2_PARTS_ALTERNATIVE_PMA":"PMA",
    "GEN2_PARTS_ALTERNATIVE_DESCRIPTION":"DESCRIPTION",
    "GEN2_PARTS_ALTERNATIVE_VENDOR":"Vendor",
    "GEN2_PARTS_ALTERNATIVE_TYPE":"TYPE",
    "GEN2_PARTS_ALTERNATIVE_APPROVAL":"TYPE",
    "GEN2_PARTS_ALTERNATIVE_DOCUMENTS":"Documents",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_A_QTY":"A.Qty",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_CONDITION":"CD",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_COST":"Cost",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_TIME":"Time",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_VENDOR":"Vendor name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_DATE":"Date",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_PN":"PN",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_USER":"User",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_CONTACT":"Contact",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_VENDOR":"Vendor name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_PN":"PN",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_CD":"CD",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_MARKET":"Market",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_DESC":"Desc",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_CATALOG_NAME":"Catalog Name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_DESC":"Description",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_VENDOR":"Vendor Name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_MOQ":"MOQ",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_QTY_PRICE":"QTY/Price",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_CD":"CD",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_UOM":"UOM",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_LOCATION":"Location",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_VENDOR":"Vendor Name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_UNIT_PRICE":"Unit Price",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_CD":"CD",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_A_QTY":"A-Qty",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_LOCATION":"Location",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_DELIVERY":"Delivery",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_SITES_LIST_DATE":"Date",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_UNIT_COST":"U.Cost",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_CD":"CD",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_BIN":"Bin",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_STATUS":"Status",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_SN":"SN",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_BACH":"Bach",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_LOT":"Lot",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_EXP":"Exp",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_KITS_LIST_PRODUCT_CODE":"Kit Product Code",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_KITS_LIST_PRODUCT_NAME":"Kit Product Name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_KITS_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_COST":"Cost",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_CD":"CD",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_BIN":"Bin",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_STATUS":"Status",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_SN":"SN",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_BACH":"Bach",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_LOT":"Lot",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_EXP":"Exp",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_UOM":"UOM",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_CURRENCY":"Currency",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_LEAD":"Lead",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_TIME":"Time",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_ACCESSORY":"Accessory",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_ALT_PN":"Alt PN",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_APPROVAL":"Approval",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_DOCUMENTS":"Documents",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_PMA":"PMA",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_CATALOG_NAME":"Catalog Name",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_CD":"CD",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_DESC":"Description",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_LOCATION":"Location",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_MOQ":"MOQ",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_QTY_PRICE":"QTY/Price",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_UOM":"UOM",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_VENDOR":"Vendor Name",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_ACCESSORY":"Accessory",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_BACH":"Bach",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_BIN":"Bin",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_CD":"CD",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_COST":"Cost",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_CURRENCY":"Currency",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_EXP":"Exp",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_LEAD":"Lead",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_LOT":"Lot",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_SN":"SN",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_STATUS":"Status",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_TIME":"Time",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_UOM":"UOM",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_CD":"CD",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_DESC":"Desc",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_MARKET":"Market",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_PN":"PN",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_VENDOR":"Vendor name",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_A_QTY":"A.Qty",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_CONDITION":"CD",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_CONTACT":"Contact",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_COST":"Cost",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_DATE":"Date",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_PN":"PN",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_TIME":"Time",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_USER":"User",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_VENDOR":"Vendor name",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_BACH":"Bach",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_BIN":"Bin",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_CD":"CD",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_EXP":"Exp",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_LOT":"Lot",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_SN":"SN",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_STATUS":"Status",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_UNIT_COST":"U.Cost",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_KITS_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_KITS_LIST_PRODUCT_CODE":"Kit Product Code",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_KITS_LIST_PRODUCT_NAME":"Kit Product Name",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_A_QTY":"A-Qty",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_CD":"CD",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_DATE":"Date",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_DELIVERY":"Delivery",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_LOCATION":"Location",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_UNIT_PRICE":"Unit Price",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_FAKE_SITES_LIST_VENDOR":"Vendor Name",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_U_COST":"Base U.Cost",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_VENDOR_MOQ":"Base vendor MOQ",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_VENDOR_QTY":"Base vendor QTY",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_TOTAL_COST":"Base total cost",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_MIN_VENDOR_ORDER":"Base min vendor order",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_U_PRICE":"Base U.Price",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_TOTAL_PRICE":"Base total price",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_CUST_MOQ":"Base MOQ to customer",
    "GEN2_RFQ_rfq_line_CORE_FIELD_RFQ_LINE_PP_BASE_CUST_QTY":"Base QTY to customer",
    "RFQ_BLOCK_TITLE_REQ_LINE_INFO":"Req line information",
    "RFQ_TAB_TITLE_HISTORY":"History",
    "RFQ_TAB_TITLE_EMARTS":"eMarts",
    "RFQ_TAB_TITLE_CATALOG":"Catalog",
    "RFQ_TAB_TITLE_SITES":"Sites",
    "RFQ_TAB_TITLE_INVENTORY":"Inventory",
    "RFQ_TAB_TITLE_ALTERNATIVES":"Alternatives",
    "RFQ_TAB_TITLE_KITS":"Kits",
    "RFQ_TAB_TITLE_CONSIGNMENTS":"Consignments",
    "RFQ_BLOCK_TITLE_VENDOR_CONTACT_INFO":"Vendor contact info",
    "RFQ_BLOCK_TITLE_LAST_ORDERS_N_QUOTES":"Last orders & quotes",
    "MENU_DEDUPLICATOR":"DEDUPLICATOR",
    "MENU_IMPORT_CONSIGNMENT_LINES":"IMPORT CONSIGNMENT",
    "MENU_IMPORT_CATALOG_LINES":"IMPORT CATALOG",
    "WIDGET_SIDEBAR_BUTTON_COMPOSE_EMAIL":"Compose Email",
    "MENU_LOCATION":"LOCATION",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_CUST_MOQ":"Base MOQ to customer",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_CUST_QTY":"Base QTY to customer",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_MIN_VENDOR_ORDER":"Base min vendor order",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_TOTAL_COST":"Base total cost",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_TOTAL_PRICE":"Base total price",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_U_COST":"Base U.Cost",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_U_PRICE":"Base U.Price",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_VENDOR_MOQ":"Base vendor MOQ",
    "GEN2_RFQ_rfq_details_CORE_FIELD_RFQ_LINE_PP_BASE_VENDOR_QTY":"Base vendor QTY",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_ALT_PN":"Alt PN",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_APPROVAL":"Approval",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_DOCUMENTS":"Documents",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_PMA":"PMA",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_ALTERNATIVES_LIST_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_CATALOG_NAME":"Catalog Name",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_CD":"CD",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_DESC":"Description",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_LOCATION":"Location",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_MOQ":"MOQ",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_QTY_PRICE":"QTY/Price",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_UOM":"UOM",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CATALOG_LIST_VENDOR":"Vendor Name",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_ACCESSORY":"Accessory",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_BACH":"Bach",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_BIN":"Bin",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_CD":"CD",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_COST":"Cost",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_CURRENCY":"Currency",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_EXP":"Exp",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_LEAD":"Lead",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_LOT":"Lot",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_SN":"SN",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_STATUS":"Status",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_TIME":"Time",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_UOM":"UOM",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_CONSIGNMENT_LIST_VENDOR":"Vendor",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_CD":"CD",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_DESC":"Desc",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_MARKET":"Market",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_PN":"PN",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_EMARTS_LIST_VENDOR":"Vendor name",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_A_QTY":"A.Qty",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_CONDITION":"CD",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_CONTACT":"Contact",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_COST":"Cost",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_DATE":"Date",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_PN":"PN",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_TIME":"Time",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_USER":"User",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_HISTORY_LIST_VENDOR":"Vendor name",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_BACH":"Bach",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_BIN":"Bin",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_CD":"CD",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_EXP":"Exp",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_LOT":"Lot",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_QTY":"QTY",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_SN":"SN",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_STATUS":"Status",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_INVENTORY_LIST_UNIT_COST":"U.Cost",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_KITS_LIST_DESCRIPTION":"Description",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_KITS_LIST_PRODUCT_CODE":"Kit Product Code",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_KITS_LIST_PRODUCT_NAME":"Kit Product Name",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_A_QTY":"A-Qty",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_CD":"CD",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_DATE":"Date",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_DELIVERY":"Delivery",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_LOCATION":"Location",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_PART_NUMBER":"Part Number",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_TYPE":"Type",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_UNIT_PRICE":"Unit Price",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_FAKE_SITES_LIST_VENDOR":"Vendor Name",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_CUST_MOQ":"Base MOQ to customer",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_CUST_QTY":"Base QTY to customer",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_MIN_VENDOR_ORDER":"Base min vendor order",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_TOTAL_COST":"Base total cost",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_TOTAL_PRICE":"Base total price",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_U_COST":"Base U.Cost",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_U_PRICE":"Base U.Price",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_VENDOR_MOQ":"Base vendor MOQ",
    "GEN2_RFQ_rfq_create_CORE_FIELD_RFQ_LINE_PP_BASE_VENDOR_QTY":"Base vendor QTY",
    "RFQ_BLOCK_TITLE_CREATE_NEW_RFQ":"Creating new RFQ",
    "RFQ_BUTTON_TEXT_SAVE":"Save",
    "RFQ_BUTTON_TEXT_ADD_VENDOR_OFFER":"Add Vendor Offer",
    "RFQ_BUTTON_TEXT_SEND_REQUEST_VENDOR":"Send Request Vendor",
    "RFQ_BUTTON_TEXT_SEARCH_MARKET":"Search Market",
    "RFQ_BUTTON_TEXT_OFFER":"Offer",
    "RFQ_BUTTON_TEXT_ALT_OFFER":"Alt. Offer",
    "RFQ_BLOCK_TITLE_CREATE_NEW_RFQ_STK_AVAILABILITY_REPAIR_CAPABILITIES":"Stk Availability / Repair Capabilities",
    "RFQ_DIALOG_TITLE_MAKE_OFFER":"Make Offer",
    "RFQ_BLOCK_TITLE_PARTS_LIST":"Parts List",
    "RFQ_BUTTON_TEXT_CHANGE_VENDOR":"Change Vendor",
    "RFQ_BUTTON_TEXT_AUTO_QUOTE":"Auto Quote",
    "RFQ_BUTTON_TEXT_GENERATE_QUOTE":"Generate Quote",
    "RFQ_BUTTON_TEXT_SHOW_QUOTES":"Show Quotes",
    "RFQ_BUTTON_TEXT_ADD_LINE":"Add Line",
    "RFQ_BUTTON_TEXT_CONFIG_VIEW":"Config View",
    "RFQ_BUTTON_TEXT_SAVE_N_ADD_NEW_PART":"Save & Add New Part"
  };