import Vue from 'vue/dist/vue.js';
import { Select, Option, Input } from 'element-ui';

export default () => {
  apos.util.widgetPlayers['catalog-filters'] = {
    selector: '[data-catalog-filters-widget]',
    player: function(el) {
      if (el.dataset.multi === 'true') {
        const SelectCD = Vue.component('multi-select-cd', {
          components: {
            'el-select': Select,
            'el-option': Option,
            'el-input': Input
          },
          template: `
            <el-select
                    v-model="selectValue"
                    multiple
                    value-key="value"
                    style="width: 85%;"
                    name="cd"
                    placeholder="">
                <el-option
                        v-for="item in options"
                        class="catalog-filter-picklist--option"
                        :key="item.value"
                        :label="item.label"
                        :value="item">
                </el-option>
            </el-select>
    `,
          data: function () {
            return {
              selectValue: [],
              options: [{
                value: 'AR',
                label: 'AR'
              }, {
                value: 'OH',
                label: 'OH'
              }, {
                value: 'SV',
                label: 'SV'
              }, {
                value: 'IN',
                label: 'IN'
              }, {
                value: 'NS',
                label: 'NS'
              }, {
                value: 'RP',
                label: 'RP'
              }, {
                value: 'NE',
                label: 'NE'
              }, {
                value: 'MOD',
                label: 'MOD'
              }, {
                value: 'BE',
                label: 'BE'
              }, {
                value: 'CL',
                label: 'CL'
              }, {
                value: 'FN',
                label: 'FN'
              }, {
                value: 'OR',
                label: 'OR'
              }, {
                value: 'RD',
                label: 'RD'
              }, {
                value: 'EX',
                label: 'EX'
              }, {
                value: 'US',
                label: 'US'
              }, {
                value: 'ST',
                label: 'ST'
              }, {
                value: 'AI',
                label: 'AI'
              }, {
                value: 'RE',
                label: 'RE'
              }, {
                value: 'RF',
                label: 'RF'
              }, {
                value: 'RB',
                label: 'RB'
              }]
            };
          },
          created() {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('cd')) {
              this.selectValue = urlParams.get('cd').split(',');
            }
          }
        });
        new Vue(SelectCD).$mount('#multi-select-cd');
      }
    }
  };
};
