import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import currency from 'vue2-filters/src/other/currency';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

export const dayjsFormat = function (value, { format, time = false, utc = false } = {}) {
  let resultDate;
  const defaultFormat = ' HH:mm';
  const targetFormat = format || defaultFormat || 'YYYY-MM-DD';

  if (value) {
    if (!utc) {
      const userTimezone = vm.$store.state[AUTH_NAMESPACE].profile.time_zone;
      const timezoneDate = dayjs(value).tz(userTimezone);
      resultDate = userTimezone !== 'UTC'
        ? dayjs(timezoneDate).format(targetFormat)
        : dayjs.utc(value).format(targetFormat);
    } else {
      resultDate = dayjs.utc(value).format(targetFormat);
    }
  }

  return resultDate || '';
};

export const dayjsToISO = function (value) {
  if (!value) return '';
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneDate = dayjs.tz(value, userTimezone).format();
  const resultDate = dayjs.utc(timezoneDate).format();

  return resultDate;
};

export default dayjsFormat;