import axios from 'axios';

const config = Object.assign({}, window.erpPortalApiConfig);

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

function addFilterToUrl(url, name, value) {
  let regex = new RegExp('[&\\?]' + name + '=');
  if (regex.test(url)) {
    regex = new RegExp('([&\\?])' + name + '=\\d+');
    url = url.replace(regex, '$1' + name + '=' + value);
  } else {
    if (url.indexOf('?') > -1) {
      url = url + '&' + name + '=' + value;
    } else {
      url = url + '?' + name + '=' + value;
    }
  }
  return url;
}

function getUrlParams(key) {
  let p = window.location.search;
  p = p.match(new RegExp(key + '=([^&=]+)'));
  return p ? p[1] : false;
}

export default () => {
  apos.util.widgetPlayers.catalog = {
    selector: '[data-catalog-widget]',
    player: function(el) {
      const searchBtn = document.getElementById('catalog-search-btn');
      const clearBtn = document.getElementById('catalog-clear-btn');
      const filtersBlocks = Array.from(document.querySelectorAll('.catalog-filter-get'));

      const defaultCatalogUrl = window.location.origin + window.location.pathname + '?module_name=CATALOG&module_view=view&record_id=0&page=1';
      searchBtn.onclick = () => {
        let filteredUrl = defaultCatalogUrl;
        for (let i = 0, length = filtersBlocks.length; i < length; i++) {
          if (filtersBlocks[i].classList.contains('catalog-filter-checkbox') && filtersBlocks[i].querySelector('input:checked')) {
            filteredUrl = addFilterToUrl(filteredUrl, filtersBlocks[i].querySelector('input:checked').name, filtersBlocks[i].querySelector('input:checked').value);// addFilterToUrl(filtersBlocks[i].querySelector('input:checked').name, filtersBlocks[i].querySelector('input:checked').value);
          } else if (filtersBlocks[i].classList.contains('catalog-filter--multi')) {
            const multiSearchCDArray = Array.from(document.querySelectorAll('.el-tag--info'));
            const searchCDUrl = [];
            for (let i = 0, length = multiSearchCDArray.length; i < length; i++) {
              searchCDUrl.push(multiSearchCDArray[i].innerText);
            }
            if (searchCDUrl.length > 0) {
              filteredUrl = addFilterToUrl(filteredUrl, 'cd', searchCDUrl);
            }
            // filteredUrl = addFilterToUrl(filteredUrl, 'cd', searchCDUrl);
          } else {
            if (filtersBlocks[i].value !== undefined && filtersBlocks[i].value !== null && filtersBlocks[i].value !== '') {
              filteredUrl = addFilterToUrl(filteredUrl, filtersBlocks[i].name, filtersBlocks[i].value);
            }
            // addFilterToUrl(filtersBlocks[i].name, filtersBlocks[i].value);
          }
        }
        const multiSearchPnArray = Array.from(document.querySelectorAll('.multi-search-pn'));
        const searchPnUrl = [];
        for (let i = 0, length = multiSearchPnArray.length; i < length; i++) {
          searchPnUrl.push(multiSearchPnArray[i].value.trim());
        }
        filteredUrl = addFilterToUrl(filteredUrl, 'pn', searchPnUrl);
        if (defaultCatalogUrl !== filteredUrl) {
          window.location = filteredUrl;
        }
      };

      const paramString = window.location.href.split('?')[1];
      const queryString = new URLSearchParams(paramString);
      for (const pair of queryString.entries()) {
        if (pair[0] !== 'module_name' && pair[0] !== 'module_view' && pair[0] !== 'record_id' && pair[0] !== 'page') {
          if (document.getElementsByName(pair[0]).length > 1) {
            for (const ratio of document.getElementsByName(pair[0])) {
              if (ratio.value === pair[1]) {
                ratio.checked = true;
              }
            }
          } else if (pair[0] === 'pn') {
            const pnSearchArray = pair[1].split(',');
            for (let i = 0, length = pnSearchArray.length; i < length; i++) {
              if (i === 0) {
                document.getElementById(`search-line-pn-${i}`).value = pnSearchArray[i];
              } else {
                addRow();
                document.getElementById(`search-line-pn-${i}`).value = pnSearchArray[i];
              }
            }
          } else if (pair[0] === 'cd') {
            continue;
          } else {
            document.getElementsByName(pair[0])[0].value = pair[1];
          }
        }
      }

      clearBtn.onclick = () => {
        for (let i = 0, length = filtersBlocks.length; i < length; i++) {
          if (filtersBlocks[i].classList.contains('catalog-filter-checkbox') && filtersBlocks[i].querySelector('input:checked')) {
            filtersBlocks[i].checked = false;
          } else {
            if (filtersBlocks[i].value !== undefined && filtersBlocks[i].value !== null && filtersBlocks[i].value !== '') {
              filtersBlocks[i].value = null;
            }
            // addFilterToUrl(filtersBlocks[i].name, filtersBlocks[i].value);
          }
        }
        window.location = defaultCatalogUrl;
      };

      // catalog pagination

      const prevBtn = document.getElementById('catalog-pagination-prev');
      const nextBtn = document.getElementById('catalog-pagination-next');

      prevBtn.onclick = () => {
        const currentPage = +getUrlParams('page');
        if (currentPage > 1) {
          window.location = addFilterToUrl(window.location.href, 'page', currentPage - 1);
        }
      };

      nextBtn.onclick = () => {
        const currentPage = +getUrlParams('page');
        window.location = addFilterToUrl(window.location.href, 'page', currentPage + 1);
      };

      // multi search

      function multiSearch() {
        let filteredUrl = defaultCatalogUrl;
        for (let i = 0, length = filtersBlocks.length; i < length; i++) {
          if (filtersBlocks[i].classList.contains('catalog-filter-checkbox') && filtersBlocks[i].querySelector('input:checked')) {
            filteredUrl = addFilterToUrl(filteredUrl, filtersBlocks[i].querySelector('input:checked').name, filtersBlocks[i].querySelector('input:checked').value);// addFilterToUrl(filtersBlocks[i].querySelector('input:checked').name, filtersBlocks[i].querySelector('input:checked').value);
          } else if (filtersBlocks[i].classList.contains('catalog-filter--multi')) {
            const multiSearchCDArray = Array.from(document.querySelectorAll('.el-tag--info'));
            const searchCDUrl = [];
            for (let i = 0, length = multiSearchCDArray.length; i < length; i++) {
              searchCDUrl.push(multiSearchCDArray[i].innerText);
            }
            if (searchCDUrl.length > 0) {
              filteredUrl = addFilterToUrl(filteredUrl, 'cd', searchCDUrl);
            }
          } else {
            if (filtersBlocks[i].value !== undefined && filtersBlocks[i].value !== null && filtersBlocks[i].value !== '') {
              filteredUrl = addFilterToUrl(filteredUrl, filtersBlocks[i].name, filtersBlocks[i].value);
            }
          }
        }
        const multiSearchPnArray = Array.from(document.querySelectorAll('.multi-search-pn'));
        const searchPnUrl = [];
        for (let i = 0, length = multiSearchPnArray.length; i < length; i++) {
          searchPnUrl.push(multiSearchPnArray[i].value.trim());
        }
        filteredUrl = addFilterToUrl(filteredUrl, 'pn', searchPnUrl);
        if (searchPnUrl.length > 0) {
          window.location = filteredUrl;
        }
      };

      document.getElementById('search-line-pn-0').addEventListener("keyup", function(event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          multiSearch();
          // document.getElementById("id_of_button").click();
        }
      });

      const multiSearchBtn = document.getElementById('multi-search-btn');
      multiSearchBtn.onclick = () => {
        multiSearch();
      };
    }
  };
};
