import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);

if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

let initialized = false;

let product = null;
let currency = null;
let pricing_information = null;
let open_rfqs = [];

const addToCart = async function () {
  this.disabled = true;

  try {
    const inputQuantity = document.querySelectorAll('section[data-product-details-widget] #input-quantity input')[0];
    const quantity = parseInt(inputQuantity.value);

    if (localStorage.getItem('erpPortalApiAuthToken')) {
      await _axios({
        method: 'POST',
        url: '/v1/quote/line/add',
        data: {
          parent_module: product.parent_module,
          parent_entity_id: product.parent_entity_id,
          parent_line_id: product.parent_line_id,
          quantity 
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`
        }
      });
    } else {
      let shoppingCart = localStorage.getItem('erpPortalShoppingCart');

      if (shoppingCart) {
        shoppingCart = JSON.parse(shoppingCart);
      } else {
        shoppingCart = [];
      }

      shoppingCart.push({
        parent_module: product.parent_module,
        parent_entity_id: product.parent_entity_id,
        parent_line_id: product.parent_line_id,
        quantity
      });

      localStorage.setItem('erpPortalShoppingCart', JSON.stringify(shoppingCart));
    }

    GnAdditionals.Notification({
      title: 'This Product was added to the Shopping Cart',
      type: 'success'
    });
  } catch (e) {
    const message = e && e.response && e.response.data && e.response.data.error;
    GnAdditionals.Notification({
      title: message || 'Something went wrong please try again',
      type: 'error'
    });
  }

  this.disabled = false;
};

const createRFQ = async function (form) {
  try {
    const inputQuantity = document.querySelectorAll('section[data-product-details-widget] #input-quantity input')[0];
    const quantity = parseInt(inputQuantity.value);
    const customer_quote = form.elements['customer_quote'].value;
    let rfq_id = null;

    const rfq = open_rfqs.find(rfq => rfq.body.number == form.elements['open_rfqs'].value);
    if (rfq) rfq_id = rfq.body.rfq_id;

    const url = rfq_id ? '/v1/rfq/line/add' : '/v1/rfq/add';

    await _axios({
      method: 'POST',
      url,
      data: {
        parent_module: product.parent_module,
        parent_entity_id: product.parent_entity_id,
        parent_line_id: product.parent_line_id,
        quantity,
        customer_quote, rfq_id
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`
      }
    });

    if (rfq_id) {
      GnAdditionals.Notification({
        title: 'Line was successfully added to RFQ',
        type: 'success'
      });
    } else {
      GnAdditionals.Notification({
        title: 'Request for Quotation has been successfully created',
        type: 'success'
      });
    }
  } catch (e) {
    const message = e && e.response && e.response.data && e.response.data.error;
    GnAdditionals.Notification({
      title: message || 'Something went wrong please try again',
      type: 'error'
    });
  }
};

const init = async () => {
  if (initialized) return;
  initialized = true;

  const urlParams = new URLSearchParams(window.location.search);

  if (!(urlParams.get('parent_module') && urlParams.get('parent_entity_id'))) return;

  try {
    let response;

    response = await _axios({
      method: 'GET',
      url: '/v1/catalog/get-product',
      params: {
        cid: window.erpPortalApiCompany,
        parent_module: urlParams.get('parent_module'),
        parent_entity_id: urlParams.get('parent_entity_id')
      }
    });

    if (!(response.data && response.data.data && response.data.data.body && response.data.data.body.id)) throw 'Product not found';

    product = response.data.data.body;
    currency = response.data.data.currency;
    pricing_information = response.data.data.pricing_information;

    const alternativeListResponse = await _axios.get(`/v1/alternative/list?cid=${window.erpPortalApiCompany}&product_id=${product.product.id}`)
    let alternativeProducts = (alternativeListResponse && alternativeListResponse.data && alternativeListResponse.data.data && alternativeListResponse.data.data.list) || [];

    const similarProductsResponse = await _axios.get(`/v1/catalog/find-products?cid=${window.erpPortalApiCompany}&page=1&pn[0]=${product.product.name}`);
    let similarProducts = (similarProductsResponse && similarProductsResponse.data && similarProductsResponse.data.data && similarProductsResponse.data.data.partsList) || [];
    similarProducts = similarProducts.filter(({ parent_module, parent_entity_id, parent_line_id }) => !(parent_module == product.parent_module && parent_entity_id == product.parent_entity_id && parent_line_id == product.parent_line_id));

    const getPriceValue = (unit_price = [], unit_price_type, pricing_information = {}, quantity) => {
      unit_price = unit_price || [];
      pricing_information = pricing_information || {};

      let result = (unit_price && unit_price[0] && unit_price[0].price) || 0;

      for (let item of (unit_price || [])) {
        if (quantity >= item.quantity && item.price) result = item.price;
      }

      if (pricing_information.calculate_type != 'disabled' && ['inventory-cost', 'catalog-line-cost', 'consignment-line-cost'].includes(unit_price_type)) {
        let cost = result;
        const subtotal = cost * quantity;

        for (let i = 1; i < 5; i++) {
          const tear = pricing_information[`tear${i}`];
          const ratio = pricing_information[`ratio${i}`];

          if (tear != null && ratio > 0 && subtotal >= tear) {
            switch (pricing_information.calculate_type) {
              case 'rate':
                result = cost / ratio;
                break;

              case 'margin':
                result = cost + ratio;
                break;

              case 'markup':
                result = cost * (1 + (ratio / 100));
                break;
            }
          }
        }

        if (cost == result) result = 0;
      }

      return parseFloat(result.toFixed(2));
    };

    product.displayed_unit_price = [];
    if (product.unit_price && product.unit_price.length > 0) {
      for (let i = 0; i < product.unit_price.length; i++) {
        const current = product.unit_price[i];
        const next = product.unit_price[i + 1];

        if (getPriceValue(product.unit_price, product.unit_price_type, pricing_information, current.quantity)) {
          product.displayed_unit_price.push({ quantity: current.quantity, price: getPriceValue(product.unit_price, product.unit_price_type, pricing_information, current.quantity) });
        }

        if (!['inventory-cost', 'catalog-line-cost', 'consignment-line-cost'].includes(product.unit_price_type)) continue;

        for (let quantity = current.quantity + 1; quantity < ((next && next.quantity) || 20000); quantity++) {
          const price = getPriceValue(product.unit_price, product.unit_price_type, pricing_information, quantity);
          if (price && price != (product.displayed_unit_price[product.displayed_unit_price.length - 1] && product.displayed_unit_price[product.displayed_unit_price.length - 1].price))  {
            product.displayed_unit_price.push({ quantity, price });
          }
        }
      }
    }

    const template = `
      <h2><%- product.product.name %> — <%- product.comment %></h2>
      <div class="row" style="justify-content: space-between;">
        <div class="col-4" style="padding: 0;">
          <div class="preview-container">
            <img class="preview-image" src="<%- (product.gallery && product.gallery.main && product.gallery.main.src) || '/images/no_image.png' %>">
          
            <img class="gallery-image" src="<%- (product.gallery && product.gallery.main && product.gallery.main.src) || '/images/no_image.png' %>" onclick="handlegalleryItem(this);">

            <% for (let item of ((product.gallery && product.gallery.additional) || [])) { %>
              <img class="gallery-image" src="<%- item.src || '/images/no_image.png' %>" onclick="handlegalleryItem(this);">
            <% } %>
          </div>
          <h4 style="padding-top: 18px;">Description</h4>
          <div class="info-row" style="border-bottom: 0; padding: 8px 0;">
            <span class="info-value" style="text-align: left; max-width: 100%; font-weight: 450; text-align: justify;"><%- product.full_description || '-' %></span>
          </div>
          <h4 style="padding-top: 18px;">Features</h4>
          <div class="info-row" style="border-bottom: 0; padding: 8px 0;">
            <span class="info-value" style="text-align: left; max-width: 100%; font-weight: 450; text-align: justify;"><%- product.features || '-' %></span>
          </div>

          <% if (alternativeProducts && alternativeProducts.length > 0) { %>
            <div style="margin-top: 40px;">
              <h4>Alternative Products</h4>

              <% for (let item of alternativeProducts) { %>
                <div class="row similar-product" style="margin-top: 20px; align-items: center;">
                  <div class="col-4" style="padding: 10px;">
                    <img class="preview-image" src="<%- (item.gallery && item.gallery.main && item.gallery.main.src) || '/images/no_image.png' %>">
                  </div>

                  <div class="col-8" style="padding: 10px;">
                    <div class="info-row">
                      <span class="info-label">Part Number</span>
                      <span class="info-value"><%- item.product.name %></span>
                    </div>
                    <div class="info-row">
                      <span class="info-label">Description</span>
                      <span class="info-value"><%- item.comment || '-' %></span>
                    </div>

                    <% if (item.quantity) { %>
                    <div class="info-row">
                      <span class="info-label">Units in Stock</span>
                      <span class="info-value"><%- item.quantity %></span>
                    </div>
                    <% } %>

                    <div class="info-row">
                      <span class="info-label">Units of Measure</span>
                      <span class="info-value"><%- item.usageunit || '-' %></span>
                    </div>

                    <% if (item.manufacturer) { %>
                      <div class="info-row">
                        <span class="info-label">Manufacturer</span>
                        <span class="info-value"><%- item.manufacturer %></span>
                      </div>
                      <% } %>

                    <div class="info-row" style="border-bottom: none; justify-content: flex-end;">
                      <span class="info-label">
                        <a style="text-decoration: none; color: #3776CF; cursor: pointer;" href="/product-details?parent_module=<%- item.parent_module %>&parent_entity_id=<%- item.parent_entity_id %>">Details</a>
                      </span>
                    </div>
                  </div>
                </div>
              <% } %>
            </div>
          <% } %>
        </div>
        <div class="col-8" style="padding: 0;">
          <div class="row" style="justify-content: space-between;">
            <div class="col-6">
              <h4>Overview</h4>
              <div class="info-row">
                <span class="info-label">Part Number</span>
                <span class="info-value"><%- product.product.name %></span>
              </div>
              <div class="info-row">
                <span class="info-label">NSN No</span>
                <span class="info-value"><%- product.nsn_number || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Condition</span>
                <span class="info-value"><%- product.condition %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Units of Measure</span>
                <span class="info-value"><%- product.usageunit %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Trace</span>
                <span class="info-value"><%- (product.tags && product.tags.trace) || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Tag date</span>
                <span class="info-value"><%- (product.tags && product.tags.date) || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Manufacturer</span>
                <span class="info-value"><%- product.manufacturer || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">S/N</span>
                <span class="info-value"><%- (product.tags && product.tags.sn) || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Warranty</span>
                <span class="info-value"><%- (product.tags && product.tags.warranty) || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Cage Code</span>
                <span class="info-value"><%- product.cage_code || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Hazmat</span>
                <span class="info-value"><%- product.hazmat ? 'Yes' : 'No' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Shelf Life Flag</span>
                <span class="info-value"><%- product.shelf_life ? 'Yes' : 'No' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Shelf Life Time (month)</span>
                <span class="info-value"><%- product.shelf_life_time || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">ECCN</span>
                <span class="info-value"><%- product.eccn || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">SCH B</span>
                <span class="info-value"><%- product.sch_b || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">HTS</span>
                <span class="info-value"><%- product.hts || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Weight</span>
                <span class="info-value"><%- product.weight || '-' %> <%- product.weight_measure || '' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Dimensions</span>
                <span class="info-value"><%- product.dimensions_x || '-' %> X <%- product.dimensions_y || '-' %> X <%- product.dimensions_z || '-' %> <%- product.dimensions_measure || '' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Category</span>
                <span class="info-value"><%- product.category || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">Group</span>
                <span class="info-value"><%- product.group || '-' %></span>
              </div>
              <div class="info-row">
                <span class="info-label">SubGroup</span>
                <span class="info-value"><%- product.sub_group || '-' %></span>
              </div>
              <h4 style="padding-top: 18px;">Certificates</h4>

              <%
                let certs = (product.tags && product.tags.certs && product.tags.certs.length > 0) ? product.tags.certs : ['-'];
              %>

              <% for (let item of certs) { %>
              <div class="info-row" style="border-bottom: 0; padding: 8px 0;">
                <span class="info-value" style="text-align: left; max-width: 100%; font-weight: 450;"><%- item %></span>
              </div>
              <% } %>

              <% if (product.documents && product.documents.length > 0) { %>

              <h4 style="padding-top: 18px;">Documents</h4>
              <% for (let item of product.documents) { %>
              <div class="info-row" style="border-bottom: 0; padding: 8px 0;">
                <a class="info-value" style="text-align: left; max-width: 100%; font-weight: 650; text-transform: uppercase; text-decoration: none; color: #3776CF; display: inline-block; word-break: break-all;" href="<%- item.src %>" target="_blank">
                  <span class="info-label" style="text-align: left;"><%- item.subtype %></span><br>
                  <%- item.filename %>
                </a>
              </div>
              <% } %>

              <% } %>
            </div>
            <div class="col-6" style="padding: 0; align-self: start;">
              <div style="padding: 40px 35px; border: 1px solid #EFEFEF; border-radius: 15px;">
                <h4>Purchase info</h4>
                <div class="info-row">
                  <span class="info-label">Minimum Order</span>
                  <span class="info-value"><%- product.moq %> <%- product.usageunit %></span>
                </div>
                <div class="info-row">
                  <span class="info-label">Units in Stock</span>
                  <span class="info-value"><%- product.quantity %> <%- product.usageunit %></span>
                </div>

                <% for (let i = 0; i < ((product.displayed_unit_price && product.displayed_unit_price.length) || 0); i++) { %>

                <%
                  const current = product.displayed_unit_price[i];
                  const next = product.displayed_unit_price[i + 1];
                %>

                <% if (product.quantity > 0) { %>

                  <% if (next && next.quantity != current.quantity && current.quantity < product.quantity) { %>
                  <div class="info-row">
                    <span class="info-label">From <%- current.quantity %> to <%- next.quantity - 1 %></span>
                    <span class="info-value"><%- currency.currency_symbol %><%- current.price %> per 1 <%- product.usageunit %></span>
                  </div>
                  <% } else { %>
                  <div class="info-row">
                    <span class="info-label">From <%- current.quantity %> to <%- product.quantity %></span>
                    <span class="info-value"><%- currency.currency_symbol %><%- current.price %> per 1 <%- product.usageunit %></span>
                  </div>
                  <% break; %>
                  <% } %>

                <% } else { %>

                  <% if (next && next.quantity != current.quantity) { %>
                  <div class="info-row">
                    <span class="info-label">From <%- current.quantity %> to <%- next.quantity - 1 %></span>
                    <span class="info-value"><%- currency.currency_symbol %><%- current.price %> per 1 <%- product.usageunit %></span>
                  </div>
                  <% } else { %>
                  <div class="info-row">
                    <span class="info-label">From <%- current.quantity %></span>
                    <span class="info-value"><%- currency.currency_symbol %><%- current.price %> per 1 <%- product.usageunit %></span>
                  </div>
                  <% } %>

                <% } %>

                <% } %>

                <% if (!localStorage.getItem('erpPortalApiAuthToken')) { %>
                <div class="info-row">
                  <span class="info-label">
                    <a style="text-decoration: none; cursor: pointer;" onclick="localStorage.setItem('erpPortalApiAuthReturnUrl', window.location.href); window.location.href = '/signin';">Login to View Price</a>
                  </span>
                </div>
                <% } %>

                <div class="info-row" <%- localStorage.getItem('erpPortalApiAuthToken') ? '' : 'style="display: none;"' %>>
                  <div id="input-quantity" style="width: 50%; max-width: 150px;">
                    <span class="minus">-</span>
                    <input type="number" min="<%- product.moq %>" value="<%- product.moq %>" />
                    <span class="plus">+</span>
                  </div>
                  <div class="info-value">
                    <span class="total-label">Total:</span>
                    <span class="total-value"></span>
                  </div>
                </div>
                <div class="info-row" style="border-bottom: 0; justify-content: flex-end; padding-right: 0; padding-bottom: 0; <%- localStorage.getItem('erpPortalApiAuthToken') ? '' : 'display: none;' %>">
                  <button id="add-to-cart" style="display: none;">Add to Cart</button>
                  <button id="create-rfq" style="display: none;">Create Request for Quotation</button>
                </div>
              </div>

              <% if (similarProducts && similarProducts.length > 0) { %>
              <div style="padding: 0 35px; margin-top: 40px;">
                <h4>Similar Products</h4>

                <% for (let item of similarProducts) { %>
                  <div class="row similar-product" style="margin-top: 20px; align-items: center;">
                    <div class="col-4" style="padding: 10px;">
                      <img class="preview-image" src="<%- (item.gallery && item.gallery.main && item.gallery.main.src) || '/images/no_image.png' %>">
                    </div>

                    <div class="col-8" style="padding: 10px;">
                      <div class="info-row">
                        <span class="info-label">Part Number</span>
                        <span class="info-value"><%- item.product.name %></span>
                      </div>
                      <div class="info-row">
                        <span class="info-label">Description</span>
                        <span class="info-value"><%- item.comment || '-' %></span>
                      </div>

                      <% if (item.quantity) { %>
                      <div class="info-row">
                        <span class="info-label">Units in Stock</span>
                        <span class="info-value"><%- item.quantity %></span>
                      </div>
                      <% } %>

                      <% if (item.lead_time) { %>
                        <div class="info-row">
                          <span class="info-label">Lead Time</span>
                          <span class="info-value"><%- item.lead_time %></span>
                        </div>
                      <% } %>

                      <div class="info-row">
                        <span class="info-label">Condition</span>
                        <span class="info-value"><%- item.condition || '-' %></span>
                      </div>
                      <div class="info-row">
                        <span class="info-label">Units of Measure</span>
                        <span class="info-value"><%- item.usageunit || '-' %></span>
                      </div>

                      <% if (item.unit_price) { %>
                        <% if (!localStorage.getItem('erpPortalApiAuthToken')) { %>
                           <div class="info-row">
                            <span class="info-label">
                              <a style="text-decoration: none; cursor: pointer;" onclick="localStorage.setItem('erpPortalApiAuthReturnUrl', window.location.href); window.location.href = '/signin';">Login to View Price</a>
                            </span>
                          </div>
                         <% } else { %>
                          <div class="info-row">
                            <span class="info-label">Unit Price</span>
                            <span class="info-value">
                              <%- currency.currency_symbol %><%- item.unit_price.toFixed(2).replace(/\\B(?=(\\d{3})+(?!\\d))/g, ',') %>
                            </span>
                          </div>
                        <% } %>                 
                      <% } %>

                      <div class="info-row" style="border-bottom: none; justify-content: flex-end;">
                        <span class="info-label">
                          <a style="text-decoration: none; color: #3776CF; cursor: pointer;" href="/product-details?parent_module=<%- item.parent_module %>&parent_entity_id=<%- item.parent_entity_id %>">Details</a>
                        </span>
                      </div>
                    </div>
                  </div>
                <% } %>

                <div class="row" style="justify-content: flex-end; margin-top: 20px;">
                  <a style="text-decoration: none; color: #3776CF; cursor: pointer;" href="/catalog?module_name=CATALOG&module_view=view&record_id=0&page=1&pn=<%- product.product.name %>">Show All...</a>
                </div>
              </div>
              <% } %>
            </div>
          </div>
        </div>
      </div>
    `;

    document.querySelectorAll('section[data-product-details-widget] #product-details-container')[0].innerHTML = ejs.render(template, { product, currency, localStorage, similarProducts, alternativeProducts });

    const addToCartButton = document.querySelectorAll('section[data-product-details-widget] #add-to-cart')[0];
    if (addToCartButton) addToCartButton.onclick = addToCart;

    const createRFQModal = document.getElementById('product-details-widget-create-rfq-modal');

    const createRFQForm = document.getElementById('product-details-widget-create-rfq-form');
    createRFQForm.addEventListener('submit', e => {
      e.preventDefault();

      const loading = document.querySelector('#product-details-widget-create-rfq-modal .loading');

      loading.style.display = 'block';

      createRFQ(createRFQForm).then(() => {
        loading.style.display = 'none';
        createRFQModal.style.display = 'none';
      })
    });

    const createRFQButton = document.querySelectorAll('section[data-product-details-widget] #create-rfq')[0];
    if (createRFQButton) {
      createRFQButton.onclick = () => {
        createRFQModal.style.display = 'block';

        const loading = document.querySelector('#product-details-widget-create-rfq-modal .loading');

        loading.style.display = 'block';

        Promise.resolve()
          .then(() => {
            const selectElement = createRFQForm.elements['open_rfqs'];

            let i, L = selectElement.options.length - 1;
            for(i = L; i >= 0; i--) {
               selectElement.remove(i);
            }

            selectElement.options[
              document.querySelector('#product-details-widget-create-rfq-form select[name="open_rfqs"]').options.length
            ] = new Option('New RFQ');

            createRFQForm.elements['customer_quote'].value = '';
          })
          .then(() => {
            if (!(product.displayed_unit_price && product.displayed_unit_price.length > 0)) {
              return _axios({
                method: 'GET',
                url: '/v1/rfq/list?page=1&order=modified_time&scenario=pagination&direction=desc&page_size=10&filters[0][field]=status&filters[0][type]=contains&filters[0][value]=Created',
              });
            }
          })
          .then(response => {
            if (response) {
              open_rfqs = response.data.data.list;

              for (let rfq of open_rfqs) {
                document.querySelector('#product-details-widget-create-rfq-form select[name="open_rfqs"]').options[
                  document.querySelector('#product-details-widget-create-rfq-form select[name="open_rfqs"]').options.length
                ] = new Option(rfq.body.number);
              }
            }
          })
          .catch(() => {})
          .finally(() => {
            loading.style.display = 'none';
          })
      };
    }

    const inputQuantity = document.querySelectorAll('section[data-product-details-widget] #input-quantity input')[0];

    const calculateTotal = () => {
      const totalValueSpan = document.querySelectorAll('section[data-product-details-widget] .total-value')[0];
      const quantity = parseInt(inputQuantity.value);
      let unitPriceValue = getPriceValue(product.unit_price, product.unit_price_type, pricing_information, quantity);

      if (product.quantity > 0 && parseInt(inputQuantity.value) > product.quantity) unitPriceValue = 0;

      if (unitPriceValue) {
        totalValueSpan.innerHTML = `${currency.currency_symbol}${(unitPriceValue * quantity).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        document.getElementById('add-to-cart').style.display = 'block';
        document.getElementById('create-rfq').style.display = 'none';
      } else {
        totalValueSpan.innerHTML = '-';
        document.getElementById('add-to-cart').style.display = 'none';
        document.getElementById('create-rfq').style.display = 'block';
      }
    };

    calculateTotal();

    inputQuantity.onchange = () => calculateTotal();

    const minus = document.querySelectorAll('section[data-product-details-widget] #input-quantity .minus')[0];
    minus.onclick = () => {
      if (parseInt(inputQuantity.value) <= product.moq) return;
      inputQuantity.value = (parseInt(inputQuantity.value) - 1).toString();
      calculateTotal();
    };

    const plus = document.querySelectorAll('section[data-product-details-widget] #input-quantity .plus')[0];
    plus.onclick = () => {
      // if (product.quantity > 0 && parseInt(inputQuantity.value) >= product.quantity) return;
      inputQuantity.value = (parseInt(inputQuantity.value) + 1).toString();
      calculateTotal();
    };

    window.handlegalleryItem = image => {
      const mainImage = document.querySelectorAll('section[data-product-details-widget] .preview-container .preview-image')[0];
      mainImage.src = image.src;

      const galleryImages = document.querySelectorAll('section[data-product-details-widget] .preview-container .gallery-image');
      for (let item of galleryImages) {
        item.style.border = 'none';
      }

      image.style.border = '5px solid #3776CF';
    };

    window.handlegalleryItem(
      document.querySelectorAll('section[data-product-details-widget] .preview-container .gallery-image')[0]
    );
  } catch (e) {
    console.log(e)
    const message = (e && e.response && e.response.data && e.response.data.error) || 'Something went wrong please try again';
    GnAdditionals.Notification({
      title: message,
      type: 'error'
    });
    document.querySelectorAll('section[data-product-details-widget] #product-details-container')[0].innerHTML = `
      <div style="padding: 5rem; margin-bottom: 3rem; text-align: center;">
        <p style="color: #a3a6ad;">${message}</p>
      </div>
    `;
  }
};

export default () => {
  apos.util.widgetPlayers['product-details'] = {
    selector: '[data-product-details-widget]',
    player: function (el) {
      if (apos.adminBar) {
        el.innerHTML = `
          <div style="border: 1px solid #c5c9d1; border-radius: 5px; padding: 5rem; margin-bottom: 3rem; text-align: center;">
            <h2 style="font-weight: bold; text-align: center;">Product Details Widget</h2>
            <p style="color: #a3a6ad;">Dynamic content will be displayed here. Disable admin mode to activate this widget.</p>
          </div>
        `;

        return;
      }

      init();
    }
  };
};
