import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);

if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

let initialized = false;

const init = () => {
  if (initialized) return;
  initialized = true;

  const urlParams = new URLSearchParams(window.location.search);
  const button = document.getElementById('pdfmaker-widget-download-pdf-btn');
  const select = document.getElementById('pdfmaker-widget-templates-select');
  const loading = document.querySelector('#pdfmaker-widget-download-pdf-btn .loading');

  _axios({
    method: 'GET',
    url: '/v1/pdfmaker/template/list',
    params: {
      module: urlParams.get('module_name'),
      record_id: urlParams.get('record_id')
    }
  })
    .then(response => {
      let i = 0;
      for (let template of ((response.data && response.data.data) || [])) {
        const opt = document.createElement('option');
        opt.value = JSON.stringify({ id: template.id, type: template.type });;
        opt.innerHTML = template.name;
        select.appendChild(opt);
        if (i === 0) select.value = opt.value;
        i++;
      }

      if (response.data && response.data.data && response.data.data.length > 0) {
        select.disabled = false;
        button.disabled = false;
      }
    });

  button.onclick = e => {
    if (!select.value) {
      GnAdditionals.Notification({
        title: 'Please select a template to export',
        type: 'error'
      });
      return;
    }

    const template = JSON.parse(select.value);
    
    Promise.resolve()
      .then(() => {
        loading.style.display = 'block';
      })
      .then(() => {
        return _axios({
          method: 'POST',
          url: '/v1/pdfmaker/export',
          data: {
            module: urlParams.get('module_name'),
            template_id: template.id,
            record_id: urlParams.get('record_id'),
            type: template.type
          }
        });
      })
      .then(response => {
        const linkSource = `data:application/pdf;base64,${response.data.data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = response.data.filename;
        downloadLink.click();
      })
      .catch((err) => {
        GnAdditionals.Notification({
          title: (err && err.response && err.response.data && err.response.data.error) ? err.response.data.error : 'Something went wrong please try again',
          type: 'error'
        });
      })
      .finally(() => {
        loading.style.display = 'none';
      });
  };
};

export default () => {
  apos.util.widgetPlayers.pdfmaker = {
    selector: '[data-pdfmaker-widget]',
    player: function (el) {
      if (apos.adminBar) {
        const button = document.getElementById('pdfmaker-widget-download-pdf-btn');
        button.onclick = e => {
          GnAdditionals.Notification({
            title: 'Disable admin mode to activate this widget',
            type: 'error'
          });
        };
        button.disabled = false;
        return;
      }

      init();
    }
  };
};