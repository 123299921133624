import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);
if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}
const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status == 401) {
      localStorage.removeItem('erpPortalApiAuthToken');
      localStorage.setItem('erpPortalApiAuthReturnUrl', window.location.href);
      setTimeout(() => window.location.href = '/signin', 3000);
      error.response.data.error = 'Unauthorized. Redirecting...';
    }

    return Promise.reject(error);
  }
);

import Vue from 'vue/dist/vue.js';
import { Select, Option, Input, Button, Popover } from 'element-ui';

export default () => {

  // conditions for show blocks auth & not auth users
  const isAuthUser = localStorage.getItem('erpPortalApiAuthToken');

  const blocksForNotAuthUsers = document.getElementsByClassName('for-not-auth');
  const blocksForAuthUsers = document.getElementsByClassName('for-auth');
  if (isAuthUser) {
    for (let i = 0; i < blocksForNotAuthUsers.length; i++) {
      blocksForNotAuthUsers[i].style.display = 'none';
    }

    for (let i = 0; i < blocksForAuthUsers.length; i++) {
      blocksForAuthUsers[i].style.display = 'block';
    }

    // for count shopping cart
    window.countShoppingCart = function() {
      _axios({
        method: 'GET',
        url: '/v1/quote/details'
      })
        .then(response => {
          document.getElementsByClassName('erp-shopping-cart--count')[0].innerHTML = response.data.data.partsList.length;
        })
        .catch((error) => {
          console.log(error);
        });
    };
    window.countShoppingCart();
  }

  const GlobalSearch = Vue.component('global-search', {
    components: {
      'el-select': Select,
      'el-option': Option,
      'el-button': Button,
      'el-popover': Popover,
      'el-input': Input
    },
    template: `
        <el-popover
                class="erp-global-search--popover"
                popper-class="erp-global-search--popover-input"
                placement="bottom"
                trigger="click">
            <div class="erp-global-search">
                <el-select
                        :value="searchString"
                        filterable
                        remote
                        popper-class="erp-global-search__dropdown"
                        class="erp-global-search__select"
                        :clearable="true"
                        :remote-method="search"
                        @hook:mounted="cancelReadOnly"
                        @visible-change="cancelReadOnly"
                        ref="select"
                        @input="handleSelect"
                        @clear="handleClear"
                        :no-data-text="'No Data'"
                        :loading="loading"
                        :loading-text="'Loading'"
                        :placeholder="'Search'">
                    <li class="el-select-dropdown__item" v-if="searchString.length > 2" @click="handleSelect(searchString)">
                        <div class="erp-global-search__dropdown-options">
                            <div class="erp-global-search__dropdown-options__image">
                                <img :src="'/images/no_image.png'">
                            </div>
                            <div class="erp-global-search__dropdown-options__info">
                                <div class="erp-global-search__dropdown-options__info--pn">{{searchString}}...</div>
                                <div class="erp-global-search__dropdown-options__info--desc"></div>
                            </div>
                        </div>
                    </li>
                    <li class="erp-global-search__dropdown-header">
                        Search Result
                    </li>
                    <el-option
                            v-for="item in globalSearchData"
                            :key="item.id"
                            :label="item.product.name"
                            :value="item.product.name">
                        <div class="erp-global-search__dropdown-options">
                            <div class="erp-global-search__dropdown-options__image">
                                <img :src="item.gallery.main ? item.gallery.main.src : '/images/no_image.png'">
                            </div>
                            <div class="erp-global-search__dropdown-options__info">
                                <div class="erp-global-search__dropdown-options__info--pn">{{item.product.name}}</div>
                                <div class="erp-global-search__dropdown-options__info--desc">{{item.comment}}</div>
                            </div>
                        </div>
                    </el-option>
                </el-select>
            </div>
            <el-button slot="reference" class="erp-global-search--btn"><img src="/images/icon-search.svg"></el-button>
        </el-popover>
    `,
    data: function () {
      return {
        searchString: '',
        loading: false,
        globalSearchData: []
      };
    },
    methods: {
      async search(query) {
        this.searchString = query;

        if (query === '') {
          this.clearResults();
        }

        if (query.length > 2) {
          const response = await _axios.get(`/v1/catalog/find-products?cid=${window.erpPortalApiCompany}&page=1&pn[0]=${query}`);

          this.globalSearchData = response.data.data.partsList;
        }
      },
      cancelReadOnly(onOff) {
        this.$nextTick(() => {
          if (!onOff) {
            const {select} = this.$refs;
            const input = select.$el.querySelector('.el-input__inner');
            input.removeAttribute('readonly');
          }
        });
      },
      clearResults() {
        this.globalSearchData = [];
      },
      handleClear() {
        this.searchString = '';
        this.clearResults();
      },
      handleSelect(item) {
        if (item.length > 0) {
          window.location = window.location.origin + '/catalog' + '?module_name=CATALOG&module_view=view&record_id=0&page=1&pn=' + item;
          this.handleClear();
        }
      }
    }
  });
  new Vue(GlobalSearch).$mount('#global-search');

  const ShoppingCartCount = Vue.component('shopping-cart-count', {
    template: `
        <div class="erp-shopping-cart--count"></div>
    `,
    data: function () {
      return {};
    }
  });
  new Vue(ShoppingCartCount).$mount('#shopping-cart-count');

};
