export default {
  name: 'GnModuleEntity',
  created() {
    this.init();
  },
  destroyed() {
    this.$commonBus.$off('gnGlobalDataReload');
  },
  computed: {
    moduleName() {
      return this.$route.meta.moduleName;
    },
    moduleView() {
      return this.$route.meta.view;
    },
    moduleData() {
      return {
        no: this.$store.state.gnStore.data.body.no,
        list: this.$store.state.gnStore.data.partsList,
        email: this.$store.state.gnStore.data.body.contact.email,
      };
    },
  },
  methods: {
    init() {
      this.$store.commit('gnStore/clear');
      this.moduleSetup = new DataInitializer({
        vm: this,
        module: this.moduleName,
        view: { view_code: this.moduleView },
        customization: 'CORE',
        company: 'DEMO',
      });
      this.moduleSetup.loadData();

      this.$commonBus.$on('gnGlobalDataReload', () => {
        this.moduleSetup.getData();
      });
    },
  },
  template: `
    <div>
      <gn-view />
    </div>
  `
};
