export default () => {
  apos.util.widgetPlayers['contact-us'] = {
    selector: '[data-contact-us-widget]',
    player: function(el) {
      function sendForm(e) {
        console.log(e);
      }
      const sendFormBtn = document.querySelector('.js-send-form-btn');
      sendFormBtn.addEventListener('click', sendForm);
    }
  };
};
