import dayjs from 'dayjs';
import currency from 'vue2-filters/src/other/currency';
import { dayjsFormat } from './dayjs';

class Formatter {
  /**
   * @param  {string} value
   * @param  {string} type - Types: date, datetime, int, text, currency
   * @return {string|Blob} newValue
   */
  autoFormat(value, type) {
    if (value === null) return '';

    switch (type) {
      case 'date':
        return dayjsFormat(value);

      case 'time':
        return this.inTime(value);

      case 'datetime':
        const time = true;
        return dayjsFormat(value, { time });

      case 'int':
        return +value;

      case 'text':
        return String(value);

      case 'currency':
        return this.inCurrency(value);

      case 'b64toBlob':
        return this.b64toBlob(value);

      default:
        return value;
    }
  }

  inDate(value, params) {
    if (!value) return '';
    let newValue = String(value);
    if (params && params.withoutTimezone) {
      newValue = newValue.replace('Z', '');
    }
    let dateFormat = 'YYYY-MM-DD';
    // const userDateFormat = store.state[AUTH_NAMESPACE].config.global__calendar_date_format;

    // if (userDateFormat) {
    //   dateFormat = userDateFormat.toUpperCase();
    // }

    return dayjs(value).isValid() ? dayjs(value).format(dateFormat) : null;
  }

  inTime(value, params) {
    if (!value) return '';

    value = String(value);
    if (params && params.withoutTimezone) {
      value = value.replace('Z', '');
    }

    const timeConversion = ''; // +store.state[AUTH_NAMESPACE].config.global__calendar_time_format === 24 ? '' : ' A';
    const timeFormat = `HH:mm${timeConversion}`;

    return dayjs(value).isValid() ? dayjs(value).format(timeFormat) : '';
  }

  inDateTime(value, params) {
    if (!value) return '';
    value = String(value);
    if (params && params.withoutTimezone) {
      value = value.replace('Z', '');
    }

    let dateFormat = 'YYYY-MM-DD';
    // const userDateFormat = store.state[AUTH_NAMESPACE].config.global__calendar_date_format;

    // if (userDateFormat) {
    //   dateFormat = userDateFormat.toUpperCase();
    // }

    const timeConversion = ''; // +store.state[AUTH_NAMESPACE].config.global__calendar_time_format === 24 ? '' : ' A';

    const dateTimeFormat = `${dateFormat} HH:mm${timeConversion}`;

    return dayjs(value).isValid() ? dayjs(value).format(dateTimeFormat) : '';
  }

  inCurrency(value) {
    value = parseFloat(value);
    if (isNaN(value)) return '';

    const currencyDecimalSeparator = '.'; // store.state[AUTH_NAMESPACE].config.global__currency_decimal_separator;
    const currencyGroupSeparator = ','; // store.state[AUTH_NAMESPACE].config.global__currency_digit_grouping_separator;
    const currencySymbol = '$'; // store.state[AUTH_NAMESPACE].config.global__currency_symbol;
    const currencyPlacement = ['$']; // store.state[AUTH_NAMESPACE].config.global__currency_symbol_placement;
    const symbolOnLeft = currencyPlacement[0] === currencySymbol;
    const spaceBetweenAmountAndSymbol = !symbolOnLeft;

    return currency(value, currencySymbol, null, {
      thousandsSeparator: currencyGroupSeparator,
      decimalSeparator: currencyDecimalSeparator,
      symbolOnLeft,
      spaceBetweenAmountAndSymbol,
    });
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
}

const formatter = new Formatter();

export const userDate = Formatter.prototype.inDate;
export const userTime = Formatter.prototype.inTime;
export const userDateTime = Formatter.prototype.inDateTime;
export const userCurrency = Formatter.prototype.inCurrency;
export const b64toBlob = Formatter.prototype.b64toBlob;
export default formatter;
