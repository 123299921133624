import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);

if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status == 401) {
      localStorage.removeItem('erpPortalApiAuthToken');
      localStorage.setItem('erpPortalApiAuthReturnUrl', window.location.href);
      setTimeout(() => window.location.href = '/signin', 3000);
      error.response.data.error = 'Unauthorized. Redirecting...';
    }

    return Promise.reject(error);
  }
);

let initialized = false;
let quote_id = null;
let open_rfqs = [];

const copyQuoteLinesToCart = async function () {
  this.disabled = true;

  try {
    await _axios({
      method: 'POST',
      url: '/v1/quote/line/copy',
      params: {
        quote_id
      }
    });

    GnAdditionals.Notification({
      title: 'This Product was added to the Shopping Cart',
      type: 'success'
    });
  } catch (e) {
    const message = e && e.response && e.response.data && e.response.data.error;
    GnAdditionals.Notification({
      title: message || 'Something went wrong please try again',
      type: 'error'
    });
  }
  window.countShoppingCart();

  this.disabled = false;
};

const createRFQ = async function (form, quote_details) {
  try {
    const customer_quote = form.elements['customer_quote'].value;
    let rfq_id = null;

    const rfq = open_rfqs.find(rfq => rfq.body.number == form.elements['open_rfqs'].value);
    if (rfq) rfq_id = rfq.body.rfq_id;

    for (let part of quote_details.partsList) {
      let parent_module = 'PARTS';
      let parent_entity_id = part.product.id;

      if (part && part.json_data && part.json_data.erp_portal && part.json_data.erp_portal.parent_module) {
        parent_module = part.json_data.erp_portal.parent_module;
        parent_entity_id = part.json_data.erp_portal.parent_entity_id;
      }

      const url = rfq_id ? '/v1/rfq/line/add' : '/v1/rfq/add';

      const response = await _axios({
        method: 'POST',
        url,
        data: {
          parent_module,
          parent_entity_id,
          quantity: part.quantity,
          customer_quote, rfq_id
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`
        }
      });

      if (url == '/v1/rfq/add') {
        rfq_id = (response.data && response.data.data && response.data.data.rfq_id) || null;
      }
    }

    if (rfq) {
      GnAdditionals.Notification({
        title: 'Line was successfully added to RFQ',
        type: 'success'
      });
    } else {
      GnAdditionals.Notification({
        title: 'Request for Quotation has been successfully created',
        type: 'success'
      });
    }
  } catch (e) {
    const message = e && e.response && e.response.data && e.response.data.error;
    GnAdditionals.Notification({
      title: message || 'Something went wrong please try again',
      type: 'error'
    });
  }
};

const init = async () => {
  if (initialized) return;
  initialized = true;

  const urlParams = new URLSearchParams(window.location.search);

  const loading = document.querySelector('#checkout-widget-modal .loading');
  const modal = document.getElementById('checkout-widget-modal');

  const button = document.getElementById('checkout-widget-btn');
  button.onclick = () => {
    Promise.resolve()
      .then(() => {
        return Promise.all([
          _axios({
            method: 'GET',
            url: '/v1/quote/details',
            params: {
              quote_id: urlParams.get('record_id')
            }
          }),
          _axios({
            method: 'GET',
            url: '/v1/shipping/info',
            params: {
              quote_id: urlParams.get('record_id')
            }
          })
        ])
      })
      .then(([response, shipping_info_respponse]) => {
        if (response.data && response.data.data && response.data.data.body && response.data.data.body.id && response.data.data.partsList.length > 0) {
          document.querySelector('#checkout-widget-modal .modal-header .total-value').innerHTML = `${response.data.data.currency.currency_symbol}${response.data.data.body.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

          for (let item of shipping_info_respponse.data.data.carrier_list) {
            document.querySelector('#checkout-widget-form select[name="ship_via"]').options[
              document.querySelector('#checkout-widget-form select[name="ship_via"]').options.length
            ] = new Option(item.carrier_name);
          }

          if (shipping_info_respponse.data.data.default_carrier.carrier_name) {
            document.querySelector('#checkout-widget-form select[name="ship_via"]').value = shipping_info_respponse.data.data.default_carrier.carrier_name;
          }

          for (let item of shipping_info_respponse.data.data.shipping_account_list) {
            document.querySelector('#checkout-widget-form select[name="ship_account"]').options[
              document.querySelector('#checkout-widget-form select[name="ship_account"]').options.length
            ] = new Option(item.shipping_account_no);
          }

          if (shipping_info_respponse.data.data.default_shipping_account.shipping_account_no) {
            document.querySelector('#checkout-widget-form select[name="ship_account"]').value = shipping_info_respponse.data.data.default_shipping_account.shipping_account_no;
          }

          if (response.data.data.body.account.term_sale && response.data.data.body.account.term_sale != 'CREDIT CARD') {
            document.querySelector('#checkout-widget-form select[name="term_sale"]').options[
              document.querySelector('#checkout-widget-form select[name="term_sale"]').options.length
            ] = new Option(response.data.data.body.account.term_sale);
            document.querySelector('#checkout-widget-form select[name="term_sale"]').value = response.data.data.body.account.term_sale;
          } else {
            document.querySelector('#checkout-widget-form select[name="term_sale"]').value = 'CREDIT CARD';
          }

          document.querySelector('#checkout-widget-form select[name="priority"]').value = 'Routine';
          if (urlParams.get('module_name') === 'SHOPPING_CART') {
            document.querySelector('#checkout-widget-form select[name="term_delivery"]').value = response.data.data.body.account.term_delivery;
            document.querySelector('#checkout-widget-form select[name="term_delivery"]').disabled = false;
            document.querySelector('#checkout-widget-form input[name="place_delivery"]').value = response.data.data.body.account.place_delivery;
            document.querySelector('#checkout-widget-form input[name="place_delivery"]').disabled = false;
          } else {
            document.querySelector('#checkout-widget-form select[name="term_delivery"]').value = response.data.data.body.term_delivery;
            document.querySelector('#checkout-widget-form input[name="place_delivery"]').value = response.data.data.body.place_delivery;
          }

          document.querySelector('#checkout-widget-form input[name="address.bill.address"]').value = response.data.data.address.bill.address;
          document.querySelector('#checkout-widget-form input[name="address.bill.pobox"]').value = response.data.data.address.bill.pobox;
          document.querySelector('#checkout-widget-form input[name="address.bill.city"]').value = response.data.data.address.bill.city;
          document.querySelector('#checkout-widget-form input[name="address.bill.state"]').value = response.data.data.address.bill.state;
          document.querySelector('#checkout-widget-form input[name="address.bill.code"]').value = response.data.data.address.bill.code;
          document.querySelector('#checkout-widget-form select[name="address.bill.country"]').value = response.data.data.address.bill.country;

          document.querySelector('#checkout-widget-form input[name="address.ship.address"]').value = response.data.data.address.ship.address;
          document.querySelector('#checkout-widget-form input[name="address.ship.pobox"]').value = response.data.data.address.ship.pobox;
          document.querySelector('#checkout-widget-form input[name="address.ship.city"]').value = response.data.data.address.ship.city;
          document.querySelector('#checkout-widget-form input[name="address.ship.state"]').value = response.data.data.address.ship.state;
          document.querySelector('#checkout-widget-form input[name="address.ship.code"]').value = response.data.data.address.ship.code;
          document.querySelector('#checkout-widget-form select[name="address.ship.country"]').value = response.data.data.address.ship.country;

          const due_date = document.querySelector('input[name="due_date"]');
          new Datepicker(due_date, {
            format: 'yyyy-mm-dd',
            autohide: true
          });

          const non_stk_line = response.data.data.partsList.find(item => {
            return (item.inventory.inventory_bit_id && item.inventory.inventory_bit_id != '0') &&
              (!item.inventory.available || item.uom.converted_qty > item.inventory.available_qty);
          });
          if (non_stk_line) {
            GnAdditionals.Notification({
              title: `Line [${non_stk_line.product.name} — ${non_stk_line.comment}] not in stock. Please remove the item or contact with administrator.`,
              type: 'error'
            });
            return;
          }

          modal.style.display = 'block';
        } else {
          GnAdditionals.Notification({
            title: 'Your shopping cart is empty',
            type: 'error'
          });
        }
      })
      .catch((error) => {
        console.log(error)
        GnAdditionals.Notification({
          title: (error.response && error.response.data && error.response.data.error) || 'Something went wrong please try again',
          type: 'error'
        });
      });
  };

  const form = document.getElementById('checkout-widget-form');
  form.addEventListener('submit', e => {
    e.preventDefault();

    loading.style.display = 'block';

    const data = {
      quote_id,
      address: {
        bill: {
          address: form.elements['address.bill.address'].value,
          pobox: form.elements['address.bill.pobox'].value,
          city: form.elements['address.bill.city'].value,
          state: form.elements['address.bill.state'].value,
          code: form.elements['address.bill.code'].value,
          country: form.elements['address.bill.country'].value,
        },
        ship: {
          address: form.elements['address.ship.address'].value,
          pobox: form.elements['address.ship.pobox'].value,
          city: form.elements['address.ship.city'].value,
          state: form.elements['address.ship.state'].value,
          code: form.elements['address.ship.code'].value,
          country: form.elements['address.ship.country'].value,
        },
        final: {
          address: form.elements['address.ship.address'].value,
          pobox: form.elements['address.ship.pobox'].value,
          city: form.elements['address.ship.city'].value,
          state: form.elements['address.ship.state'].value,
          code: form.elements['address.ship.code'].value,
          country: form.elements['address.ship.country'].value,
        }
      },
      place_delivery: form.elements['place_delivery'].value,
      priority: form.elements['priority'].value,
      due_date: form.elements['due_date'].value,
      term_delivery: form.elements['term_delivery'].value,
      client_po: form.elements['client_po'].value,
      body: {
        term_sale: form.elements['term_sale'].value,
        ship_via: form.elements['ship_via'].value,
        ship_account: form.elements['ship_account'].value,
      }
    };

    _axios({
      method: 'POST',
      url: '/v1/quote/checkout',
      data
    })
      .then(response => {
        if (response.data && response.data.data && response.data.data.so_id) {
          GnAdditionals.Notification({
            title: 'Your order has been created successfully. Redirecting...',
            type: 'success'
          });

          if (document.querySelector('#checkout-widget-form select[name="term_sale"]').value == 'CREDIT CARD') {
            setTimeout(() => window.location.href = `/order-payment?so_id=${response.data.data.so_id}`, 3000);
          } else {
            setTimeout(() => window.location.href = `/customer-order?module_name=PO&module_view=details&record_id=${response.data.data.so_id}`, 3000);
          }
        } else {
          GnAdditionals.Notification({
            title: (response && response.data && response.data.data && response.data.data.message) || 'Something went wrong please try again',
            type: 'error'
          });
        }
      })
      .then(() => {
        modal.style.display = 'none';
      })
      .catch(error => {
        GnAdditionals.Notification({
          title: (error && error.response && error.response.data && error.response.data.error) || 'Something went wrong please try again',
          type: 'error'
        });
      })
      .finally(() => {
        loading.style.display = 'none';
      });
  });

  const buttonAddToCart = document.querySelectorAll('#add-to-cart-btn')[0];

  if (!buttonAddToCart) {
    return;
  }

  if (urlParams.get('module_name') != 'QUOTE') {
    buttonAddToCart.style.display = 'none';
    return;
  }

  button.disabled = true;
  buttonAddToCart.disabled = true;

  quote_id = urlParams.get('record_id');

  const quote_details = await _axios({
    method: 'GET',
    url: '/v1/quote/details',
    params: { quote_id }
  })
    .then(response => {
      if (!(response.data && response.data.data && response.data.data.body && response.data.data.body.id)) {
        return null;
      }

      return response.data.data;
    })
    .catch((error) => {
      console.log(error)
      GnAdditionals.Notification({
        title: (error.response && error.response.data && error.response.data.error) || 'Something went wrong please try again',
        type: 'error'
      });
    });

  let is_quote_valid = false;

  if (quote_details) {
    const valid_until = quote_details.body.valid_until;

    if (valid_until && (new Date(valid_until) > new Date())) {
      is_quote_valid = true;
    }
  }

  buttonAddToCart.disabled = false;

  if (is_quote_valid) {
    button.disabled = false;
    buttonAddToCart.onclick = copyQuoteLinesToCart;
  } else {
    const createRFQModal = document.getElementById('checkout-widget-create-rfq-modal');

    const createRFQForm = document.getElementById('checkout-widget-create-rfq-form');
    createRFQForm.addEventListener('submit', e => {
      e.preventDefault();
  
      const loading = document.querySelector('#checkout-widget-create-rfq-modal .loading');
  
      loading.style.display = 'block';
  
      createRFQ(createRFQForm, quote_details).then(() => {
        loading.style.display = 'none';
        createRFQModal.style.display = 'none';
      })
    });

    buttonAddToCart.onclick = () => {
      createRFQModal.style.display = 'block';

      const loading = document.querySelector('#checkout-widget-create-rfq-modal .loading');

      loading.style.display = 'block';

      Promise.resolve()
        .then(() => {
          const selectElement = createRFQForm.elements['open_rfqs'];

          let i, L = selectElement.options.length - 1;
          for(i = L; i >= 0; i--) {
             selectElement.remove(i);
          }

          selectElement.options[
            document.querySelector('#checkout-widget-create-rfq-form select[name="open_rfqs"]').options.length
          ] = new Option('New RFQ');

          createRFQForm.elements['customer_quote'].value = '';
        })
        .then(() => {
          return _axios({
            method: 'GET',
            url: '/v1/rfq/list?page=1&order=modified_time&scenario=pagination&direction=desc&page_size=10&filters[0][field]=status&filters[0][type]=contains&filters[0][value]=Created',
          });
        })
        .then(response => {
          if (response) {
            open_rfqs = response.data.data.list;

            for (let rfq of open_rfqs) {
              document.querySelector('#checkout-widget-create-rfq-form select[name="open_rfqs"]').options[
                document.querySelector('#checkout-widget-create-rfq-form select[name="open_rfqs"]').options.length
              ] = new Option(rfq.body.number);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          loading.style.display = 'none';
        })
    };

    buttonAddToCart.innerHTML = 'Create Request for Quotation'
  }
};

export default () => {
  apos.util.widgetPlayers.checkout = {
    selector: '[data-checkout-widget]',
    player: function (el) {
      if (apos.adminBar) {
        const form = document.getElementById('checkout-widget-form');
        form.addEventListener('submit', e => {
          e.preventDefault();
          GnAdditionals.Notification({
            title: 'Disable admin mode to activate this widget',
            type: 'error'
          });
        });
        return;
      }

      init();
    }
  };
};
